import request from '../axios'
import qs from 'qs'

// 首页
const platformMonitorRequest = {
  // 平台人数实时监控
  getPlatformNum() {
    return request({
      url: '/manage/platform/online/count',
      method: 'get'
    })
  },
  // 平台人数趋势图
  getPlatformOnlineData(params) {
    return request({
      url: '/manage/platform/online/count/day',
      method: 'get',
      params
    })
  },
  // 每日人数波峰图
  getPlatformDailyData(params) {
    return request({
      url: '/manage/platform/online/count/month',
      method: 'get',
      params
    })
  },
  // 平台人数实时监控-租户列表详情
  platformTableData(params) {
    return request({
      url: '/manage/platform/online/count/details',
      method: 'get',
      params
    })
  },
  // 平台人数实时监控-累计使用人数图
  getPlatformDeilyUseData(params) {
    return request({
      url: '/manage/platform/online/day/number',
      method: 'get',
      params
    })
  },
  // 平台人数实时监控-累计使用人数图-详情列表
  platformDeilyUseTableDetails(params) {
    return request({
      url: '/manage/platform/online/day/number/details',
      method: 'get',
      params
    })
  },
}

export default platformMonitorRequest;