import request from '../axios'
import qs from 'qs'

const jobRequest = {
  // 批量导出审核学生名单数据
  auditStuListexport(data) {
    return request({
      url: '/task/platform/verify/exportStudentNamesDate',
      method: 'post',
      data
    })
  },
  // 重新导入学生名单
  stuListAgainExport(data) {
    return request({
      url: '/task/platform/verify/reimport',
      method: 'post',
      data
    })
  },
  // 批量上传审批单
  batchBulkUploadVerify(data) {
    return request({
      url: '/task/platform/bulkUploadVerify',
      method: 'post',
      data
    })
  },
  // 导出教学点订单统计总计详情
  exportStationOrderTotal(data) {
    return request({
      url: '/task/platform/order/totalStationListExport',
      method: 'post',
      data
    })
  },
  // 导出学校订单统计总计详情
  exportSchoolOrderTotal(data) {
    return request({
      url: '/task/platform/order/totalTenantListExport',
      method: 'post',
      data
    })
  },
  // 课程安排导出
  exportCourseRes(data) {
    return request({
      url: '/task/platform/course/courseExport',
      method: 'post',
      data
    })
  },
}



export default jobRequest;