import progressComponent from './index.vue'

const progressModle = {
  install: function (Vue) {
    Vue.component("progressModle", progressComponent);
  }

  // install: function () {
  //   // 创建一个Vue的“子类”组件
  //   const progressConstructor = Vue.extend(progressComponent)

  //   // 创建一个该子类的实例,并挂载到一个元素上
  //   const instance = new progressConstructor()

  //   // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
  //   instance.$mount(document.createElement('div'))
  //   document.body.appendChild(instance.$el)

  //   // 在Vue的原型链上注册方法，控制组件
  //   Vue.prototype.$progress = {
  //     show: () => {
  //       instance.show = true
  //     },
  //     hide: () => {
  //       instance.show = false
  //     },
  //   }
  //   // 用于window中可以直接使用
  //   window.$progress = Vue.prototype.$progress
  // },
  
  // show: function () {
  //   console.log('show')
  // }
}

export default progressModle;

// import Vue from "vue";

// let instance;
// const progressConstructor = Vue.extend(progressComponent); 
// instance = new progressConstructor({
//     el:document.createElement("div")
// });
// instance.show = false;
// const progress = {
//     show(options = {}){
//         instance.show = true;
//         document.body.appendChild(instance.$el);
//         instance.text = options.text;
//     },
//     hide() {
//         instance.show = false;
//     }
// }
// export default {
//     install(){
//         if(!Vue.progress){
//           Vue.prototype.$progress = progress;
//           window.$progress = Vue.prototype.$progress
//         }
//         // Vue.mixin({
//         //     created(){
//         //         this.$loading = Vue.$loading;
//         //     }
//         // })
//     }
// }