import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './api/request'
import vuescroll from 'vuescroll'
import { Button, Input, InputNumber, Container, Aside, Main, Header, Menu, Submenu, MenuItem, MenuItemGroup, Row, Col, Message, Loading, MessageBox, Dialog, Breadcrumb, BreadcrumbItem, Avatar, Image, Table, TableColumn, Pagination, scrollbar, Upload, Dropdown, DropdownMenu, DropdownItem, Tabs, TabPane, Form, FormItem, Select, Option, OptionGroup, DatePicker, TimeSelect, TimePicker, Cascader, CascaderPanel, Popover, Popconfirm, Descriptions, DescriptionsItem, Radio, RadioGroup, RadioButton, Switch, Tooltip, Tree, Checkbox, CheckboxButton, CheckboxGroup, Tag, Steps, Step, Collapse, CollapseItem, Transfer } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Mixin from './mixins'
import way from './api/encapsulation'
import uploadAli from "./api/uploadAli";
import lodash from "lodash"

import { preventReClick } from './api/preventReClick'
import loadingmodle from './components/loading/Loading'
import progressModle from './components/progressModle/progressModle'
import moment from "moment";
import common from "./components/utils/common";
import echarts from "vue-echarts"
import { use } from 'echarts/core'
import 'echarts/lib/component/dataZoom'
// 手动引入 ECharts 各模块来减小打包体积
import {
  LineChart,
  PieChart,
  BarChart
} from 'echarts/charts';
import {
  UniversalTransition,
  LabelLayout
} from 'echarts/features';
import {
  CanvasRenderer
} from 'echarts/renderers';
import {
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent
} from 'echarts/components'

// 全局注册组件
import GL_Component from "@/api/globalComponent";
Vue.use(GL_Component);

use([
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  PieChart,
  BarChart,
  CanvasRenderer,
  UniversalTransition,
  LabelLayout,
  TitleComponent,
  ToolboxComponent
]);
Vue.component('v-chart', echarts)//全局注册

Vue.mixin(Mixin)

Vue.use(Button)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Container)
Vue.use(Aside);
Vue.use(Main);
Vue.use(Header);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Row);
Vue.use(Col);
Vue.use(vuescroll);
Vue.use(Loading.directive);
Vue.use(Dialog);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Avatar);
Vue.use(Image);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(scrollbar);
Vue.use(Upload);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Cascader);
Vue.use(CascaderPanel);
Vue.use(Popover);
Vue.use(Popconfirm);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Switch);
Vue.use(Tooltip);
Vue.use(Tree);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Tag);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Transfer);

Vue.use(loadingmodle)
Vue.use(progressModle)

Vue.prototype.$vuescrollConfig = {
  bar: {
    showDelay: 500,//在鼠标离开容器后多长时间隐藏滚动条
    onlyShowBarOnScroll: false,//当页面滚动时显示
    keepShow: false,//是否一直显示
    background: '#fff',
    opacity: .3,
    hoverStyle: false,
    specifyBorderRadius: false,
    minSize: false,
    size: '6px',
    disable: false,//是否禁用滚动条
  },
  name: 'vueScroll' // 在这里自定义组件名字，默认是vueScroll
}

Vue.config.productionTip = false

Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$moment = moment

// 请求封装
Vue.prototype.$request = request
Vue.prototype.$common = common;
Vue.prototype.$isShowModle = way.isShowModle
Vue.prototype.$way = way
Vue.prototype.$uploadAli = uploadAli
Vue.prototype.$preventReClick = preventReClick; // 节流
Vue.prototype.$lodash = lodash; // 节流

/** 权限指令,对按钮权限的控制 **/
Vue.directive('allow', {
  bind: function (el, binding) {
    let hasPerData = JSON.parse(sessionStorage.getItem('hasPerData'));
    // 通过当前按钮，获取所在权限组的组名
    let permissGropName = binding.value; 
    // 按组名查看已有标识组中是否有同值的，有则展示按钮，无则隐藏按钮
    if (Array.isArray(hasPerData)) {
      if (hasPerData.indexOf(permissGropName) == -1) {
        el.style.display = "none"
      }
    }
  }
})

//全局守卫
router.beforeEach((to, from, next) => {
  // if (to.query.routerType == 'nonPage' && !store.state.addRouteStatus) {
  //   store.commit('setAddRoutes')
  // }
  let token = sessionStorage.getItem('token')
  // 如果已经登录，随便访问
  if (token) {
    next();
  } else {
    if (to.path !== '/Login') {
      // 如果没有登录，跳登录页面
      next({ path: '/Login' })
    } else {
      // 如果没有登录，但访问的是login，那ok
      next()
    }
  }
})

/* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    // router.replace(targetPath);
    location.reload();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
