import request from '../axios'
import qs from 'qs'

const courseRequest = {
  // 资源匹配模板库
  // 列表查询(分页)
  resourceTemplateList(params) {
    return request({
      url: '/manage/syscourse/template/list',
      method: 'get',
      params
    })
  },
  // 课程修改
  updateResourceTemplate(data) {
    return request({
      url: '/manage/syscourse/template/update',
      method: 'put',
      data
    })
  },
  //学校管理-课程管理-批量取消匹配
  batchCancelCourseware(data){
    return request({
      url: '/manage/systeachingplan/batchCancelCourseware',
      method: 'put',
      data
    })
  }
}

export default courseRequest;