import request from '../axios'
import qs from 'qs'

const systemRequest = {
  // 获取登录设置
  getMajorListLogin(params) {
    return request({
      url: '/admin/param/login/get',
      method: 'get',
      params
    })
  },
  // 登录设置
  loginSetting(data) {
    return request({
      url: '/admin/param/login/set',
      method: 'put',
      data
    })
  },
  // 备份分页查询
  backUpList(params) {
    return request({
      url: '/admin/backup/page',
      method: 'get',
      params
    })
  },
  // 获取备份设置
  backUpData() {
    return request({
      url: '/admin/backup/getSetting',
      method: 'put'
    })
  },
  // 备份设置
  setBackUpData(data) {
    return request({
      url: '/admin/backup/setup',
      method: 'put',
      data
    })
  },
  // 删除
  deleteBackUpData(params) {
    return request({
      url: '/admin/backup/del',
      method: 'delete',
      params
    })
  },
  // 还原
  resetBackUpData(params) {
    return request({
      url: '/admin/backup/reduction',
      method: 'put',
      params
    })
  },
  // 备份
  backUpDatas() {
    return request({
      url: '/admin/backup/backUp',
      method: 'post'
    })
  },
  // 是否支持验证码登录 返回true为支持 false为不支持
  showCode() {
    return request({
      url: '/admin/param/login/sms/isShow',
      method: 'get'
    })
  },
}

export default systemRequest;