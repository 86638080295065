import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)

const state = {
  isCollapse: false, // 导航菜单是否展开
  isNotActive: false, // 导航菜单是否失去焦点
  asideMenuData: [], // 导航菜单数据
  watchKey: '', // 本地存储的key
  aliBaseUrl: 'https://image.youkexuetang.cn', // 阿里云保存静态资源基准路径 ykxt-css
  aliFileUrl: 'https://files.youkexuetang.cn', // 阿里云保存静态资源基准路径 ykxt
  progress: -1, // 大文件分片上传进度
  isProgress: false, // 是否展示大文件分片上传进度组件
  progressTips: '',
  addRouteStatus: false, // 是否有动态路由
}

let arr = []
let rData = []

const mutations = {
  // 登录返回数据
  // getLogin(state, data) {
  //   state.loginData = data
  // },
  collapseEvent(state, boolVal) {
    state.isCollapse = boolVal;
  },
  notActiveEvent(state, boolVal) {
    state.isNotActive = boolVal;
  },
  getMenu(state, arrVal) {
    state.asideMenuData = arrVal;
  },
  watchKeyEvent(state, strVal) {
    state.watchKey = strVal;
  },
  changeProgress(state, num) {
    state.progress = num;
  },
  changeIsProgress(state, Bool) {
    state.isProgress = Bool;
  },
  changePogressTips(state, str) {
    state.progressTips = str;
  },
  // changeRoute(state, obj) {
  //   let List = router.history.current.matched
  //   List.splice(0, 1)
  //   let index = router.options.routes.findIndex(i => i.path == "/Home")
  //   let dataArr = router.options.routes
  //   let routeList = dataArr[index].children
  //   rData = routeList
  //   // console.log(queryRoute);
  //   // console.log(List)
  //   List.forEach((item, index) => queryRoute(rData, item, obj.rObj))
  //   state.addRouteStatus = true
  //   router.matcher.addRoute(obj.fRoute, obj.rObj)
  //   console.log(routeList,77777)
  //   dataArr = JSON.stringify(dataArr)
  //   sessionStorage.setItem('routeList', dataArr)
  // },
  // setAddRoutes(state, obj) {
  //   console.log(router)
  //   let routeList = JSON.parse(sessionStorage.getItem('routeList'))
  //   router.matcher.addRoute(routeList)
  // }
}

const queryRoute = (data, item, obj) => {
  let List = router.history.current.matched
  data.forEach((ite, ind) => {
    if (ite.name == item.name) {
      arr.push(ind)
      rData = data[ind].children
      if (List.length == arr.length && Array.isArray(data.children)) {
        let indexs = data.children.findIndex(i => i.name == obj.name)
        if(indexs == -1) data.children.push(obj)
      } else if (List.length == arr.length && !Array.isArray(data.children)) {
        data.children = []
        data.children.push(obj)
      }
    }
  })
}

const actions = {
  
}

export default new Vuex.Store({
  state,
  mutations,
  actions: {},
  modules: {}
})
