import request from '../axios'
import qs from 'qs'

// 首页
const ueditorRequest = {
  // 获取百度编辑器配置文件
  getUeditorConfig() {
    return request({
      url: '/manage/UEditor/getConfigInfo',
      method: 'get'
    })
  },

  // 百度编辑器图片上传
  uploadImgUeditor(data) {
    return request({
      url: '/manage/UEditor/editUpload',
      method: 'post',
      data
    })
  },
}

export default ueditorRequest;