import request from '../axios'
import qs from 'qs'

// 首页
const indexRequest = {
  // 获取课程资源
  getCourseResources() {
    return request({
      url: '/manage/platform/index/getCourseResources',
      method: 'get'
    })
  },
  // 学校分布情况-饼图
  getSchoolDistribution() {
    return request({
      url: '/manage/platform/index/getSchoolDistribution',
      method: 'get'
    })
  },
  // 各学校缴费率-柱状图
  getSchoolPaymentRates() {
    return request({
      url: '/manage/platform/index/getSchoolPaymentRates',
      method: 'get'
    })
  },
  // 学生情况-总汇
  getStuTotal() {
    return request({
      url: '/manage/platform/index/getStudentPayCount/getTotal',
      method: 'get'
    })
  },
  // 学生情况-曲线图
  getYearTotalList() {
    return request({
      url: '/manage/platform/index/getStudentPayCount/getYearTotalList',
      method: 'get'
    })
  },
}

export default indexRequest;