import request from '../axios'
import qs from 'qs'

const orderManageRequest = {
  // 订单管理
  // 统计数据
  orderDataStatistics(params) {
    return request({
      url: '/manage/sysOrder/page/getOrderStatistics',
      method: 'get',
      params
    })
  },

  // 缴费开通审核
  // 统计数据
  feeDataStatistics(params) {
    return request({
      url: '/manage/sysVerify/page/getVerifyStatistics',
      method: 'get',
      params
    })
  },

  // 关闭权限审核
  // 分页查询
  fincloseverifyList(params) {
    return request({
      url: '/manage/fincloseverify/page',
      method: 'get',
      params
    })
  },
  // 批量导出关闭审核
  batchExportFincloseverify(params) {
    return request({
      url: '/task/platform/closeVerify/reviewExport',
      method: 'get',
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false })
      }
    })
  },
  // 添加关闭权限审批单
  addFincloseverify(data) {
    return request({
      url: '/task/platform/addCloseVerify',
      method: 'post',
      data
    })
  },
  // 通过id删除
  deleteFincloseverify(params) {
    return request({
      url: '/manage/fincloseverify/deleteById',
      method: 'delete',
      params
    })
  },
  // 审批确认
  enterFincloseverify(data) {
    return request({
      url: '/manage/fincloseverify/approvalProcess',
      method: 'put',
      data
    })
  },
  // 平台端-关闭权限-重新提交
  againFincloseverify(params) {
    return request({
      url: `/manage/fincloseverify/resubmit/${params.id}`,
      method: 'put'
    })
  },
  // 修改关闭权限审核
  editFincloseverify(data) {
    return request({
      url: '/manage/fincloseverify/updateFinCloseVerify',
      method: 'put',
      data
    })
  },
  // 详情
  fincloseverifyDetails(params) {
    return request({
      url: `/manage/fincloseverify/detail/${params.id}`,
      method: 'get'
    })
  },
  // 平台端-审核详情-学生名单错误提醒(id为审批单的id)
  closeAuditErrTips(params) {
    return request({
      url: `/manage/fincloseverifystudentnames/studentListErrorReminder/${params.id}`,
      method: 'get'
    })
  },
  // 学生名单分页查询
  closeStudentList(params) {
    return request({
      url: '/manage/fincloseverifystudentnames/page',
      method: 'get',
      params
    })
  },
  // 学生名单-删除
  deleteCloseStudent(params) {
    return request({
      url: `/manage/fincloseverifystudentnames/deleteVerifyStudentNames/${params.id}`,
      method: 'delete'
    })
  },
  // 学生名单-修改
  editCloseStudent(data) {
    return request({
      url: '/manage/fincloseverifystudentnames/updateVerifyStudentNames',
      method: 'put',
      data
    })
  },
  // 学生名单-添加学生
  addVerifyStudentNames(data) {
    return request({
      url: '/manage/fincloseverifystudentnames/addVerifyStudentNames',
      method: 'post',
      data
    })
  },

  // 删除学生审核
  // 分页查询
  deleteStudentList(params) {
    return request({
      url: '/manage/studentdelverify/page',
      method: 'get',
      params
    })
  },
  // 审批确认
  enterStudentdelverify(data) {
    return request({
      url: '/manage/studentdelverify/approvalProcess',
      method: 'put',
      data
    })
  },
  // 通过id删除
  deleteStudentdelverify(params) {
    return request({
      url: '/manage/studentdelverify/deleteById',
      method: 'delete',
      params
    })
  },
  // 详情
  studentdelverifyDetails(params) {
    return request({
      url: `/manage/studentdelverify/detail/${params.id}`,
      method: 'get'
    })
  },
  // 平台端-删除学生审核-重新提交
  againStudentdelverify(params) {
    return request({
      url: `/manage/studentdelverify/resubmit/${params.id}`,
      method: 'put'
    })
  },
  // 修改删除学生审核
  editStudentdelverify(data) {
    return request({
      url: '/manage/studentdelverify/updateStuStudentDelVerify',
      method: 'put',
      data
    })
  },
  // 添加删除学生审批单
  addStudentdelverify(data) {
    return request({
      url: '/task/platform/addDelStudentVerify',
      method: 'post',
      data
    })
  },
  // 批量导出删除学生审核
  batchExportDelStudentVerify(params) {
    return request({
      url: '/task/platform/delVerify/reviewExport',
      method: 'get',
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false })
      }
    })
  },
  // 详情
  studentVerifyDetails(params) {
    return request({
      url: `/manage/studentdelverify/detail/${params.id}`,
      method: 'get'
    })
  },
  // 平台端-审核详情-学生名单错误提醒(id为审批单的id)
  deleteAuditErrTips(params) {
    return request({
      url: `/manage/studentdelverifynames/studentListErrorReminder/${params.id}`,
      method: 'get'
    })
  },
  // 审批确认
  enterStudentdelverify(data) {
    return request({
      url: '/manage/studentdelverify/approvalProcess',
      method: 'put',
      data
    })
  },
  // 学生名单分页查询
  deleteStudentNamesList(params) {
    return request({
      url: '/manage/studentdelverifynames/page',
      method: 'get',
      params
    })
  },
  // 学生名单-删除
  deleteToStudentNames(params) {
    return request({
      url: `/manage/studentdelverifynames/deleteVerifyStudentNames/${params.id}`,
      method: 'delete'
    })
  },
  // 学生名单-修改
  updateToStudentNames(data) {
    return request({
      url: '/manage/studentdelverifynames/updateVerifyStudentNames',
      method: 'put',
      data
    })
  },
}

export default orderManageRequest;