import { post, formPost, get, deleteWay } from '../axios'
import request from '../axios'
import qs from 'qs'
import way from '../encapsulation'
import { Message } from "element-ui"

// 登录
const loginYk = (data) => {
  // let val = new URLSearchParams();
  // val.append('username', data.username);
  // val.append('password', data.password);
  let dataObj = qs.stringify({ 'username': data.username, 'password': data.password })
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': '0',
      'Authorization': 'Basic cGlnOnBpZw==',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'post',
    params: { randomStr: 'blockPuzzle', grant_type: 'password', code: data.code },
    data: dataObj
  })
}

//刷新token
const refreshToken = (data) => {
  let dataObj = qs.stringify({ "refresh_token": data });
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': '0',
      'Authorization': 'Basic cGlnOnBpZw==',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'post',
    params: { grant_type: 'refresh_token' },
    data: dataObj
  })
}

// 退出登录
const logout = () => {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

//获取验证图片  以及token
const reqGet = (data) => {
  return request({
    url: '/code',
    method: 'get',
    data
  })
}

//滑动或者点选验证
const reqCheck = (data) => {
  return request({
    url: '/code/check',
    method: 'post',
    params: data
  })
}

// 第一次登录修改密码接口
const firstLogin = (data, token) => {
  return request({
    url: '/admin/center/pt/first/updatePassword',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    method: 'put',
    data
  })
}

// 获取本用户的权限菜单
const getMenus = (data, callback) => {
  get('/admin/menu/getMenu', data).then(res => {
    return callback(res)
  })
}

// 图片上传
const uploadPic = (data, callback) => {
  formPost('/manage/file/upload', data).then(res => {
    return callback(res)
  })
}

// 多文件上传
const uploadPics = (data, callback) => {
  let flag = false
  let form = new FormData();
  data.forEach((item, index) => {
    if(way.checkUrl(item.name)) flag = true
    form.append('files', item);
  })
  if (flag) {
    Message({
      message: "文件名内不能带有+、%",
      type: "warning",
    });
    return new Promise((resolve, reject) => {
      reject({})
    })
  } else {
    return request({
      url: '/manage/file/uploadFiles',
      method: 'post',
      headers: { "content-type": "multipart/form-data" },
      data: form
    })
  }
}

// 文件下载
const download = (url, data) => {
  return request({
    // url: '/api' + url,
    url: '/api' + '/教学计划导入错误数据.xlsx',
    method: 'post',
    responseType: 'blob',
    emulateJSON: true
  })
}

// 学校列表表格数据
const getListData = (data, callback) => {
  get('/manage/systenant/page', data).then(res => {
    return callback(res)
  })
}

// 省份下拉框
const getProvice = (params, callback) => {
  get('/manage/systenant/pt/provinces', params).then(res => {
    return callback(res)
  })
}

// 获取省份
const getProviceNew = (params, callback) => {
  get('/manage/region/getProvinces', params).then(res => {
    return callback(res)
  })
}

const getPermissionProvinces = (params, callback) => {
  get('/manage/region/getPermissionProvinces', params).then(res => {
    return callback(res)
  })
}

// 获取市
const getCity = (params, callback) => {
  get('/manage/region/getCities', params).then(res => {
    return callback(res)
  })
}

// 获取教学点下拉框
const getTheDropDownBoxs = (params, callback) => {
  get('/manage/sysStations/getTheDropDownBox', params).then(res => {
    return callback(res)
  })
}

// 获取教学点下拉框-new
const getTheDropDownStr = (params, callback) => {
  get('/manage/sysStations/getTheDropDownStr', params).then(res => {
    return callback(res)
  })
}

// 修改学校
const editSchoolInfo = (data) => {
  return request({
    url: '/manage/systenant/updateTenant',
    method: 'put',
    data
  })
}

// 验证学校编码是否存在,存在返回True
const regEncode = (params, callback) => {
  get(`/manage/systenant/verifyEncoding/${params.encoding}`).then(res => {
    return callback(res)
  })
}

// 通过id查询
const idQuery = (params, callback) => {
  get(`/manage/systenant/${params.id}`).then(res => {
    return callback(res)
  })
}

// 获取学校下拉框
const getTenantDownData = (params, callback) => {
  get('/manage/systenant/pt/getTenantDown', params).then(res => {
    return callback(res)
  })
}

// 新增学校
const addSchoolInfo = (data, callback) => {
  post('/manage/systenant/addTenant', data).then(res => {
    return callback(res)
  })
}

// 审核
const examine = (data) => {
  return request({
    url: '/manage/systenant/examine',
    method: 'put',
    data
  })
}

// 审核失败后重新提交
const resubmitNew = (data) => {
  return request({
    url: '/manage/systenant/pt/resubmit',
    method: 'put',
    data
  })
}

// 学校发布
const schoolPublish = (params) => {
  return request({
    url: '/manage/systenant/pt/publish',
    method: 'put',
    params
  })
}

// 下架学校
const offSchool = (params) => {
  return request({
    url: '/manage/systenant/pt/off',
    method: 'put',
    params
  })
}

// 重新上架学校
const onSchool = (params) => {
  return request({
    url: '/manage/systenant/pt/on',
    method: 'put',
    params
  })
}

// 教学计划 得分规则
const getScoreRule = (params, callback) => {
  get('/admin/dict/scoreRule', params).then(res => {
    return callback(res)
  })

}

const scoreRulePublish = (id, data) => {
  return request({
    url: '/admin/dict/updateScoreRule/' + id,
    method: 'put',
    data
  })
}

// 教学计划 列表查询(分页)
const getList = (params, callback) => {
  get('/manage/systeachingplan/list', params).then(res => {
    return callback(res)
  })
}

// 教学计划 获取下载模版地址
const getDownUrl = (params, callback) => {
  get('/admin/template/teachPlanImport', params).then(res => {
    return callback(res)
  })
}

// 教学计划 教学计划导入
const importExcel = (data, callback) => {
  post('/task/platform/teachPlan/import', data).then(res => {
    return callback(res)
  })
}

// 教学计划 删除
const deleteList = (params, callback) => {
  deleteWay('/manage/systeachingplan/delete', params).then(res => {
    return callback(res)
  })
}

// 教学计划 课程删除检测
const checkCourseHasStu = (params) => {
  return request({
    url: `/manage/syscourse/checkDeleteCourse/${params.courseId}/${params.teachingPlanId}`,
    method: 'delete'
  })
}

// 课程安排 批量课程删除检测-old
const checkBatchDeleteCourse = (data) => {
  return request({
    url: '/manage/syscourse/checkBatchDeleteCourse',
    method: 'delete',
    data
  })
}

// 课程安排 批量课程删除检测-new
const checkCourseDel = (params) => {
  return request({
    url: '/manage/systeachingplan/checkDeleteTeachingPlan',
    method: 'delete',
    params
  })
}

// 课程安排 课程批量删除
const batchDeleteCourse = (data) => {
  return request({
    url: '/manage/syscourse/batchDeleteCourse',
    method: 'delete',
    data
  })
}

// 课程安排 课程删除
// const deleteCourseSingle = (params) => {
//   return request({
//     url: `/manage/syscourse/deleteCourse/${params.courseId}/${params.teachingPlanId}`,
//     method: 'delete'
//   })
// }

// 教学计划 修改
const updateList = (params, callback) => {
  deleteWay('/manage/systeachingplan/update', params).then(res => {
    return callback(res)
  })
}

// 课程管理 列表查询(分页)
const getSyscourseList = (params, callback) => {
  get('/manage/syscourse/list', params).then(res => {
    return callback(res)
  })
}
// 课程管理 课程删除
const delSyscourse = (data) => {
  return request({
    url: '/manage/syscourse/deleteCourse/'+data.courseId+'/'+data.teachingPlanId,
    method: 'delete',
  })
}
// 课程管理 编辑弹窗信息
const getSyscourseInfo = (params, callback) => {
  get('/manage/syscourse/getCourseLearn/'+params.courseId+'/'+params.teachingPlanId).then(res => {
    return callback(res)
  })
}
// 课程管理 编辑
const updateSyscourse = (data) => {
  return request({
    url: '/manage/syscourse/updateCourseLearn',
    data,
    method: 'put',
  })
}

// 学校列表-课程管理 列表查询(分页)
const getcommonList = (params, callback) => {
  get('/manage/syscourse/commonList', params).then(res => {
    return callback(res)
  })
}
// 学校列表-课程管理 批量清空课件
const batchUpdateCourseware = (params, callback) => {
  return request({
    url: '/manage/syscourse/batchUpdateCoursewareByIds',
    method: 'put',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}
// 学校列表-课程管理 课程匹配列表查询(分页)
const getCourseList = (params, callback) => {
  get('/manage/syscourse/course/list', params).then(res => {
    return callback(res)
  })
}

// 课程管理 课件列表查询(分页)
const getCoursewareList = (params, callback) => {
  get('/manage/syscourse/courseware/list', params).then(res => {
    return callback(res)
  })
}

// 课程管理 教学计划导出
const exportPlan = (data, callback) => {
  post('/task/platform/course/export', data).then(res => {
    return callback(res)
  })
}

// 课程管理 教辅材料列表查询(分页)
const getEbookList = (params, callback) => {
  get('/manage/syscourse/ebook/list', params).then(res => {
    return callback(res)
  })
}
// 课程管理 试卷列表查询(分页)
const getExamPaperList = (params, callback) => {
  get('/manage/syscourse/examPaper/list', params).then(res => {
    return callback(res)
  })
}

// 课程管理 题库列表查询(分页)
const getQuestionList = (params, callback) => {
  get('/manage/syscourse/question/list', params).then(res => {
    return callback(res)
  })
}

// 课程管理 课程修改
const editCourse = (data) => {
  return request({
    url: '/manage/syscourse/update',
    method: 'put',
    data
  })
}
// 课程管理 试卷修改
const editExam = (data) => {
  return request({
    url: '/manage/syscourse/examPaper/update',
    method: 'put',
    data
  })
}

// 课程管理 课程题库修改
const editCourseQues = (data) => {
  return request({
    url: '/manage/syscourse/questionBank/update',
    method: 'put',
    data
  })
}
// 课程管理 课程题库修改
const addCourse = (data) => {
  return request({
    url: '/manage/syscourse/add',
    method: 'post',
    data
  })
}

// 课程管理 课件预览
const vodShow = (params, callback) => {
  get('/manage/syscourse/vod', params).then(res => {
    return callback(res)
  })
}

// 课程管理 获取课程下的教学计划课程匹配
const teachingPlanCourseList = (params) => {
  return request({
    url: '/manage/syscourse/teachingPlanCourse/list',
    method: 'get',
    params
  })
}

// 课程管理 批量修改课程下的教学计划课程匹配
const updateTeachingPlan = (data) => {
  return request({
    url: '/manage/syscourse/teachingPlanCourse/update',
    method: 'put',
    data
  })
}

// 学生管理

// 通过id查询
const queryToId = (params, callback) => {
  get(`/manage/sysstudent/${params.id}`).then(res => {
    return callback(res)
  })
}

// 查看站点在校生人数
const checkNum = (params, callback) => {
  get(`/manage/sysstudent/NumOfSites/${params.tenant_id}`, { batch: params.batch }).then(res => {
    return callback(res)
  })
}

// 查看学校在校生人数
const checkSchoolNum = (params, callback) => {
  get(`/manage/sysstudent/numOfStudentsEnrolled/${params.tenant_id}`).then(res => {
    return callback(res)
  })
}

// 分页查询
const getStudentData = (params, callback) => {
  get('/manage/sysstudent/page', params).then(res => {
    return callback(res)
  })
}

// 新增学生
const addStudents = (data, callback) => {
  post('/manage/sysstudent/save', data).then(res => {
    return callback(res)
  })
}

// 通过id删除学生
const deleteByIds = (params, callback) => {
  deleteWay('/manage/sysstudent/deleteById', params).then(res => {
    return callback(res)
  })
}

// 重置学生密码
const resetpassword = (params) => {
  return request({
    url: `/admin/user/st/resetPassword/${params.loginNum}`,
    method: 'put',
  })
}

// 修改学生
const updateStudent = (data) => {
  return request({
    url: '/manage/sysstudent/updateStudent',
    method: 'put',
    data
  })
}

// 学生导出
const exportStudent = (data) => {
  return request({
    url: '/task/platform/student/export',
    method: 'put',
    data
  })
}

// 学生导入
const importStudent = (data) => {
  return request({
    url: '/task/platform/student/import',
    method: 'put',
    data
  })
}

// 获取学生导入的样板
const getStudentTem = (callback) => {
  get('/admin/template/studentImport').then(res => {
    return callback(res)
  })
}

// 关闭学生账号学习权限
const closeStudent = (params) => {
  return request({
    url: `/manage/sysstudent/permissions/close/${params.studentId}`,
    method: 'put'
  })
}

// 订单管理
// 查看详情
const checkStatus = (params, callback) => {
  get(`/manage/sysOrder/${params.id}`).then(res => {
    return callback(res)
  })
}

// 分页查询
const getOrderData = (params, callback) => {
  get('/manage/sysOrder/page', params).then(res => {
    return callback(res)
  })
}

// 改价
const platformOrderChangePrice = (data) => {
  return request({
    url: '/manage/sysOrder/platformOrderChangePrice',
    method: 'put',
    data
  })
}

// 通过id删除
const deleteOrderById = (params, callback) => {
  deleteWay('/manage/sysOrder/deleteById', params).then(res => {
    return callback(res)
  })
}

// 批量删除
const batchDeleteOrder = (params, callback) => {
  // deleteWay('/manage/sysOrder/deleteByIds', params).then(res => {
  //   return callback(res)
  // })
  return request({
    url: '/manage/sysOrder/deleteByIds',
    method: 'delete',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}


// 批量导出
const exportOrderData = (data) => {
  return request({
    url: '/task/platform/order/exportByIds',
    method: 'post',
    data
  })
}
// 发票设置提交
const postInvoiceSet = (data) => {
  return request({
    url: '/manage/sysOrder/invoiceSet',
    method: 'post',
    data
  })
}
// 发票设置查询
const getInvoiceSet = (params) => {
  return request({
    url: '/manage/sysOrder/getInvoiceSet',
    method: 'get',
    params
  })
}

// 审批管理
// 通过id查询
const auditQueryById = (params, callback) => {
  get(`/manage/sysVerify/${params.id}`).then(res => {
    return callback(res)
  })
}

// 重新提交
const auditReSubmit = (params) => {
  return request({
    url: `/manage/sysVerify/resubmit/${params.id}`,
    method: 'put'
  })
}

// 修改审批
const auditEdit = (data) => {
  return request({
    url: '/manage/sysVerify/updateSysVerify',
    method: 'put',
    data
  })
}

// 审批学生名单管理
const getStudentNames = (params, callback) => {
  get('/manage/sysVerifyStudentNames/page', params).then(res => {
    return callback(res)
  })
}

// 添加学生
const addAuditStudentNames = (data) => {
  return request({
    url: '/manage/sysVerifyStudentNames/addVerifyStudentNames',
    method: 'post',
    data
  })
}

// 修改
const updateVerifyStudentNames = (data) => {
  return request({
    url: '/manage/sysVerifyStudentNames/updateVerifyStudentNames',
    method: 'put',
    data
  })
}

// 删除
const deleteBystuId = (params, callback) => {
  deleteWay(`/manage/sysVerifyStudentNames/deleteVerifyStudentNames/${params.id}`).then(res => {
    return callback(res)
  })
}

// 添加审批
const addSysVerifyAudit = (data, callback) => {
  post('/manage/sysVerify/addSysVerify', data).then(res => {
    return callback(res)
  })
}

// 审批确认
const approvalProcess = (data) => {
  return request({
    url: '/manage/sysVerify/approvalProcess',
    method: 'put',
    data
  })
}

// 批量不通过
const batchFailed = (params) => {
  return request({
    url: '/manage/sysVerify/batchFailed',
    method: 'put',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 补缴
const makeUpEvent = (data) => {
  return request({
    url: '/manage/sysVerify/makeUp',
    method: 'put',
    data
  })
}

// 批量补缴
const makeUpBatchEvent = (data) => {
  return request({
    url: '/manage/sysVerify/batchMakeUp',
    method: 'put',
    data
  })
}

// 关闭
const closeUpPay = (params) => {
  return request({
    url: `/manage/sysVerify/closeUp/${params.id}`,
    method: 'put'
  })
}

// 通过补缴id删除补缴
const deleteByAuditPayId = (params, callback) => {
  deleteWay('/manage/sysVerify/deleteMakeUpById', params).then(res => {
    return callback(res)
  })
}

// 通过id删除
const deleteByAuditId = (params, callback) => {
  deleteWay('/manage/sysVerify/deleteById', params).then(res => {
    return callback(res)
  })
}

// 批量删除
const deleteByAuditIdList = (params) => {
  return request({
    url: '/manage/sysVerify/deleteByIdList',
    method: 'delete',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 分页查询
const getAuditTableData = (params, callback) => {
  get('/manage/sysVerify/page', params).then(res => {
    return callback(res)
  })
}

// 批量导出
const reviewAuditExport = (data) => {
  return request({
    url: '/task/platform/verify/reviewExport',
    method: 'post',
    data
  })
}

// 改价
const auditChangePrice = (data) => {
  return request({
    url: '/manage/sysVerify/changePrice',
    method: 'put',
    data
  })
}

// 编辑开票信息
const updateInvoiceInfo = (data) => {
  return request({
    url: '/manage/sysVerify/editInvoiceInfo',
    method: 'put',
    data
  })
}

// 纸质发票开票
const paperInvoiceBill = (data) => {
  return request({
    url: '/manage/sysVerify/paperInvoiceBilling',
    method: 'put',
    data
  })
}

// 上传发票
const upLoadInvoice = (data) => {
  return request({
    url: '/manage/sysVerify/uploadInvoice',
    method: 'put',
    data
  })
}

// 获取下载模版地址
// const getAuditModule = (callback) => {
//   get('/manage/sysVerify/template').then(res => {
//     return callback(res)
//   })
// }

// 获取学校单价
const getSchoolPay = (params, callback) => {
  get('/manage/sysVerify/getUnitPriceByTenantId', params).then(res => {
    return callback(res)
  })
}

// 获取学校单价
const batchSubmission = (data) => {
  return request({
    url: '/manage/sysVerify/batchSubmission',
    method: 'put',
    data
  })
}

// 审核过来-学生名单错误提醒(id为审批单的id)
const getAuditErrTips = (params, callback) => {
  get(`/manage/sysVerify/studentListErrorReminder/${params.id}`).then(res => {
    return callback(res)
  })
}

// 任务中心
// 分页查询
const getTaskTableData = (params, callback) => {
  get('/task/page', params).then(res => {
    return callback(res)
  })
}

// 专业库管理
// 专业下拉框数据
const getMajorData = (params, callback) => {
  get(`/manage/sysmajor/majorDown/${params.tenantId}`).then(res => {
    return callback(res)
  })
}

const getAddStudentMajorData = (params, callback) => {
  get(`/manage/sysmajor/majorDown/${params.tenantId}/${params.eduCategory}`).then(res => {
    return callback(res)
  })
}

// 模糊查询
const fuzzyQuery = (params, callback) => {
  get('/manage/sysmajor/search', params).then(res => {
    return callback(res)
  })
}

// 删除
const libraryDelete = (params, callback) => {
  deleteWay('/manage/sysmajor/delete', params).then(res => {
    return callback(res)
  })
}

// 列表查询(分页)
const getlibData = (params, callback) => {
  get('/manage/sysmajor/list', params).then(res => {
    return callback(res)
  })
}

// 日志管理
// 登录日志分页列表
const getLoginLogPage = (params, callback) => {
  get('/admin/log/loginLogPage', params).then(res => {
    return callback(res)
  })
}

// 操作日志分页列表
const getOperateLogPage = (params, callback) => {
  get('/admin/log/operateLogPage', params).then(res => {
    return callback(res)
  })
}

// 获取模块下拉框
const getModuleDowns = (params, callback) => {
  get('/admin/menu/getModuleDown', params).then(res => {
    return callback(res)
  })
}

// 版本管理
// 通过id查询
const queryVersionById = (params, callback) => {
  get(`/manage/sysVersion/${params.id}`).then(res => {
    return callback(res)
  })
}

// 新增版本
const addVersion = (data, callback) => {
  post('/manage/sysVersion/add', data).then(res => {
    return callback(res)
  })
}

// 修改版本
const editVersion = (data) => {
  return request({
    url: '/manage/sysVersion/update',
    method: 'put',
    data
  })
}

// 查询版本号
const queryVersionByType = (params, callback) => {
  get(`/manage/sysVersion/getVersionId/${params.type}`).then(res => {
    return callback(res)
  })
}

// 通过id删除
const deleteByVerId = (params, callback) => {
  deleteWay('/manage/sysVersion/deleteById', params).then(res => {
    return callback(res)
  })
}

// 批量删除
const deleteByVerIdList = (params) => {
  return request({
    url: '/manage/sysVersion/deleteByIdList',
    method: 'delete',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 导出版本
const exportVersion = (params, callback) => {
  return request({
    url: '/task/platform/version/exportVersion',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 分页查询
const getVersionTable = (params, callback) => {
  get('/manage/sysVersion/page', params).then(res => {
    return callback(res)
  })
}

// 角色管理
// 新增角色表
const addRoleList = (data, callback) => {
  post('/admin/role/pt/add', data).then(res => {
    return callback(res)
  })
}

// 平台端删除角色
const deleteByRoleId = (params, callback) => {
  deleteWay(`/admin/role/pt/del/${params.id}`).then(res => {
    return callback(res)
  })
}

// 批量删除角色
const deleteByRoleIdList = (params) => {
  return request({
    url: '/admin/role/pt/delBatch',
    method: 'delete',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 平台端禁用角色
const disableRole = (params) => {
  return request({
    url: `/admin/role/pt/disable/${params.id}`,
    method: 'put'
  })
}

// 平台端批量禁用角色
const disableRoles = (params) => {
  return request({
    url: '/admin/role/pt/disableBatch',
    method: 'put',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 平台端启用角色
const enableRole = (params) => {
  return request({
    url: `/admin/role/pt/enable/${params.id}`,
    method: 'put'
  })
}

// 平台端批量启用角色
const enableRoles = (params) => {
  return request({
    url: '/admin/role/pt/enableBatch',
    method: 'put',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 权限管理接口
const setupPermissionsApi = (data) => {
  return request({
    url: '/admin/role/pt/setupPermissions',
    method: 'put',
    data
  })
}

// 平台端修改角色
const updateRole = (data) => {
  return request({
    url: '/admin/role/pt/update',
    method: 'put',
    data
  })
}

// 角色用户页
const getRolePages = (params, callback) => {
  get(`/admin/role/pt/users/${params.roleId}`).then(res => {
    return callback(res)
  })
}

// 角色分页列表
const getRoleTable = (params, callback) => {
  get('/admin/role/pt/page', params).then(res => {
    return callback(res)
  })
}

// 角色下拉接口
const getRoleDown = (params, callback) => {
  get('/admin/role/pt/roleDown', params).then(res => {
    return callback(res)
  })
}


// 获取菜单(树形展示)
const getMenuTree = (params, callback) => {
  get('/admin/menu/getRoleMenuTree', params).then(res => {
    return callback(res)
  })
}

// 用户管理模块

// 平台端新增用户
const addUsers = (data, callback) => {
  post('/admin/user/pt/addUser', data).then(res => {
    return callback(res)
  })
}

// 平台端删除用户
const deleteByUserId = (params, callback) => {
  deleteWay(`/admin/user/pt/del/${params.id}`).then(res => {
    return callback(res)
  })
}

// 批量删除用户
const deleteByUserIdList = (params) => {
  return request({
    url: '/admin/user/pt/delBatch',
    method: 'delete',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 平台端禁用用户
const disableUser = (params) => {
  return request({
    url: `/admin/user/pt/disable/${params.id}`,
    method: 'put'
  })
}

// 平台端批量禁用用户
const disableUsers = (params) => {
  return request({
    url: '/admin/user/pt/disableBatch',
    method: 'put',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 平台端启用用户
const enableUser = (params) => {
  return request({
    url: `/admin/user/pt/enable/${params.id}`,
    method: 'put'
  })
}

// 平台端批量启用用户
const enableUsers = (params) => {
  return request({
    url: '/admin/user/pt/enableBatch',
    method: 'put',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

// 平台端获取用户分页列表
const getUserTable = (params, callback) => {
  get('/admin/user/pt/page', params).then(res => {
    return callback(res)
  })
}

// 平台端获取用户详情
const getUserDetails = (params, callback) => {
  get('/admin/user/pt/getUserDetail', params).then(res => {
    return callback(res)
  })
}

// 平台端修改用户
const editUserInfo = (data) => {
  return request({
    url: '/admin/user/pt/updateUser',
    method: 'put',
    data
  })
}

// 重置密码
const resetUserPassword = (params) => {
  return request({
    url: `/admin/user/pt/resetPassword/${params.userId}`,
    method: 'put'
  })
}

// 入学批次-旧
const getGoSchoolOld = (params, callback) => {
  get(`/admin/dict/type/${params.type}`).then(res => {
    return callback(res)
  })
}

// 入学批次-新
const getGoSchool = (params, callback) => {
  get(`/admin/dict/item/batch/${params.tenantId}`).then(res => {
    return callback(res)
  })
}

// 系统管理
// 字典管理
// 字典表格查询
const queryDictionary = (params, callback) => {
  get('/admin/dict/page', params).then(res => {
    return callback(res)
  })
}

// 字典类型
const getDiction = (params, callback) => {
  get(`/admin/dict/type/${params.type}`).then(res => {
    return callback(res)
  })
}

// 编辑数字字典
const editDiction = (data) => {
  return request({
    url: '/admin/dict/',
    method: 'put',
    data
  })
}

// 添加数字字典
const addDiction = (data, callback) => {
  post(`/admin/dict/`, data).then(res => {
    return callback(res)
  })
}

// 删除数字字典
const deleteDiction = (params) => {
  return request({
    url: `/admin/dict/${params.id}`,
    method: 'delete'
  })
}

// 查看字典项
const watchDictionItem = (params, callback) => {
  get('/admin/dict/item/page', params).then(res => {
    return callback(res)
  })
}

// 字典项添加
const addItemObj = (data, callback) => {
  post('/admin/dict/item', data).then(res => {
    return callback(res);
  })
}

// 字典项编辑
const putItemObj = (data, callback) => {
  return request({
    url: '/admin/dict/item',
    method: 'put',
    data
  })
}

// 字典项删除
const delItemObj = (params) => {
  return request({
    url: `/admin/dict/item/${params.id}`,
    method: 'delete'
  })
}

// 菜单管理
// 菜单列表
const getMenuList = (params, callback) => {
  get('/admin/menu', params).then(res => {
    return callback(res)
  })
}

// 菜单树
const fetchMenuTree = (params, callback) => {
  get('/admin/menu/tree', params).then(res => {
    return callback(res)
  })
}

// 菜单树删除
const delMenuObj = (id) => {
  return request({
    url: `/admin/menu/${id}`,
    method: 'delete'
  })
}

const getMenuObj = (id, callback) => {
  get(`/admin/menu/${id}`).then(res => {
    return callback(res)
  })
}

// 菜单树 添加
const addMenuObj = (data, callback) => {
  post('/admin/menu', data).then(res => {
    return callback(res)
  })
}

// 菜单树 编辑
const putMenuObj = (data, callback) => {
  return request({
    url: '/admin/menu',
    method: 'put',
    data
  })
}

const getPersonCenterInfo = (callback) => {
  get('/admin/center/pt/personCenter').then(res => {
    return callback(res);
  });
}

const getUsernamePrefix = (callback) => {
  get("/admin/param/publicValue/TENANT_USERNAME_PREFIX").then(res => {
    return callback(res)
  })
}

const getSchoolTypeDown = (callback) => {
  get("/admin/dict/item/down/tenant_type").then(res => {
    return callback(res)
  })
}

// 文章管理
// 获取栏目
const getColumn = (params, callback) => {
  get(`/admin/article/getColumn/${params.id}`).then(res => {
    return callback(res)
  })
}

// 获取栏目详情
const getColumnDetails = (params, callback) => {
  get(`/admin/article/getSysArticleCategory/${params.id}`).then(res => {
    return callback(res)
  })
}

// 通过栏目id查看文章(用户协议&隐私条款可用)
const getArticleContById = (params, callback) => {
  get(`/admin/article/getArticleContentByCategoryId/${params.categoryId}`).then(res => {
    return callback(res)
  })
}

// 新增文章表
const addArticle = (data, callback) => {
  post('/admin/article/addArticle', data).then(res => {
    return callback(res)
  })
}

// 获取文章分页
const getArticle = (params, callback) => {
  get('/admin/article/page', params).then(res => {
    return callback(res)
  })
}

// 通过文章id查看文章
const watchArticle = (params, callback) => {
  get(`/admin/article/getArticleContentById/${params.id}`).then(res => {
    return callback(res)
  })
}

// 删除文章
const deleteArticleById = (params) => {
  return request({
    url: `/admin/article/deleteById/${params.id}`,
    method: 'delete'
  })
}

// 修改文章表
const editArticleById = (data) => {
  return request({
    url: '/admin/article/updateArticle',
    method: 'put',
    data
  })
}

// 获取学籍状态数据
const getDictItemByType = (params) => {
  return request({
    url: `/admin/dict/item/down/${params.type}`,
    method: 'get'
  })
}

// 助学管理
// 查询生源分页
const getAidClassStuPage = (params, callback) => {
  get('/manage/tchaidclass/getAidStudentPage', params).then(res => {
    return callback(res)
  })
}
// 查询争议列表(学生详情)
const getDisputeList = (params, callback) => {
  get('/manage/tchaidclass/dispute/list', params).then(res => {
    return callback(res)
  })
}
// 查看班级弹框
const getAidClassList = (params, callback) => {
  get(`/manage/tchaidclass/getAidClassList/${params.valId}/${params.valTenantId}`).then(res => {
    return callback(res)
  })
}
// 关闭账号
const closeAccount = (data, callback) => {
  return request({
    url: '/manage/tchaidclass/aidStudent/closeAccount',
    method: 'put',
    data
  })
}
// 获取班级下拉框
const getClassDownVo = (params, callback) => {
  get('/manage/tchaidclass/getClassDownVo', params).then(res => {
    return callback(res)
  })
}

// 模板管理
const getModule = (params) => {
  return request({
    url: `/admin/template/${params.labelName}`,
    method: "get",
  });
};

export default {
  loginYk,
  refreshToken,
  logout,
  reqGet,
  reqCheck,
  firstLogin,
  getMenus,
  uploadPic,
  uploadPics,
  download,
  getListData,
  getProvice,
  getProviceNew,
  getPermissionProvinces,
  getCity,
  getTheDropDownBoxs,
  getTheDropDownStr,
  editSchoolInfo,
  regEncode,
  idQuery,
  getTenantDownData,
  addSchoolInfo,
  examine,
  resubmitNew,
  schoolPublish,
  offSchool,
  onSchool,
  getList,
  importExcel,
  getDownUrl,
  deleteList,
  checkCourseHasStu,
  checkBatchDeleteCourse,
  checkCourseDel,
  batchDeleteCourse,
  // deleteCourseSingle,
  updateList,
  getSyscourseList,
  getcommonList,
  batchUpdateCourseware,
  getCourseList,
  getCoursewareList,
  exportPlan,
  getEbookList,
  getQuestionList,
  editCourse,
  editExam,
  editCourseQues,
  addCourse,
  vodShow,
  teachingPlanCourseList,
  updateTeachingPlan,
  queryToId,
  checkNum,
  checkSchoolNum,
  getStudentData,
  addStudents,
  deleteByIds,
  resetpassword,
  updateStudent,
  exportStudent,
  importStudent,
  getStudentTem,
  closeStudent,
  checkStatus,
  getOrderData,
  platformOrderChangePrice,
  deleteOrderById,
  batchDeleteOrder,
  exportOrderData,
  getInvoiceSet,
  postInvoiceSet,
  auditQueryById,
  auditReSubmit,
  auditEdit,
  addSysVerifyAudit,
  approvalProcess,
  batchFailed,
  makeUpEvent,
  makeUpBatchEvent,
  closeUpPay,
  deleteByAuditPayId,
  deleteByAuditId,
  deleteByAuditIdList,
  getAuditTableData,
  reviewAuditExport,
  auditChangePrice,
  updateInvoiceInfo,
  paperInvoiceBill,
  upLoadInvoice,
  // getAuditModule,
  getSchoolPay,
  batchSubmission,
  getAuditErrTips,
  getTaskTableData,
  getMajorData,
  getAddStudentMajorData,
  fuzzyQuery,
  libraryDelete,
  getlibData,
  getLoginLogPage,
  getOperateLogPage,
  getModuleDowns,
  queryVersionById,
  addVersion,
  editVersion,
  queryVersionByType,
  deleteByVerId,
  deleteByVerIdList,
  exportVersion,
  getVersionTable,
  addRoleList,
  deleteByRoleId,
  deleteByRoleIdList,
  disableRole,
  disableRoles,
  enableRole,
  enableRoles,
  setupPermissionsApi,
  updateRole,
  getRolePages,
  getRoleTable,
  getRoleDown,
  getMenuTree,
  addUsers,
  deleteByUserId,
  deleteByUserIdList,
  disableUser,
  disableUsers,
  enableUser,
  enableUsers,
  getUserTable,
  getUserDetails,
  editUserInfo,
  resetUserPassword,
  getGoSchoolOld,
  getGoSchool,
  queryDictionary,
  getDiction,
  editDiction,
  addDiction,
  deleteDiction,
  watchDictionItem,
  addItemObj,
  putItemObj,
  delItemObj,
  getMenuList,
  fetchMenuTree,
  getMenuObj,
  addMenuObj,
  putMenuObj,
  delMenuObj,
  getPersonCenterInfo,
  getStudentNames,
  addAuditStudentNames,
  updateVerifyStudentNames,
  deleteBystuId,
  getUsernamePrefix,
  getSchoolTypeDown,
  getExamPaperList,
  getScoreRule,
  scoreRulePublish,
  getColumn,
  getColumnDetails,
  getArticleContById,
  addArticle,
  getArticle,
  watchArticle,
  deleteArticleById,
  editArticleById,
  getDictItemByType,
  delSyscourse,
  getSyscourseInfo,
  updateSyscourse,
  getAidClassStuPage,
  getDisputeList,
  getAidClassList,
  closeAccount,
  getClassDownVo,
  getModule,
}
