<template>
  <div class="progress" v-if="show">
    <div class="p-box">
      <div class="load_cont"></div>
      <span class="p-num">{{progressVal}}%</span>
      <div class="litleTip">{{progressTips}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'progressModle',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    progressTips: {
      type: String,
      default: '文件上传中······'
    }
  },
  data() {
    return {
      progressVal: 0
    }
  },
  watch: {
    '$store.state.progress'(val) {
      setTimeout(() => {
        let str = String(val * 100);
        if(str.length == 3) {
          this.progressVal = str;
        } else {
          this.progressVal = str.substr(0, 2);
        }
      }, 300);
    }
  }
}
</script>

<style lang="less">
  .progress {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.3);
    .p-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 200px;
      height: 200px;
      // line-height: 200px;
      font-size: 50px;
      font-weight: 800;
      // text-align: center;
      opacity: 0;
      animation: btnmove 5s forwards;
      -webkit-animation: btnmove 5s forwards; /* Safari and Chrome */
    }
    .load_cont {
      width: 100%;
      height: 100%;
      border-radius: 45%;
      background: rgb(255, 255, 255);
      animation: zhuan 3s infinite;
      -webkit-animation: zhuan 3s infinite; /* Safari and Chrome */
    }
    .p-num {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .litleTip {
      position: absolute;
      left: 50%;
      top: 115%;
      transform: translate(-50%, -50%);
      font-size: 25px;
      color: #fff;
      font-weight: 300;
      width: 600px;
      text-align: center;
      line-height: 0px;
    }
    @keyframes btnmove
    {
      0% {
        opacity: 0;
      }	
      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes btnmove
    {
      0% {
        opacity: 0;
      }	
      100% {
        opacity: 1;
      }
    }

    @keyframes zhuan
    {
      0% {
        transform: rotate(0deg);
      }	
      100% {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes zhuan
    {
      0% {
        transform: rotate(0deg);
      }	
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>