import request from '../axios'
import qs from 'qs'

const commonRequest = {
  // 获取专业下拉框
  getMajorList(params) {
    return request({
      url: `/manage/school/tchExam/getMajorDown/${params.batch}/${params.tenantId}/${params.eduCategory}`,
      method: 'get'
    })
  },
  // 专业模糊查询
  getScStudentMajorData(params) {
    return request({
      url: `/manage/sysmajor/majorDown/${params.tenantId}/${params.eduCategory}`,
      method: "get",
    })
  },
  // 获取学期下拉
  getSemesterDownVo(params) {
    return request({
      url: "/manage/Semester/getSemesterDownVo",
      method: "get",
      params
    })
  }
}

export default commonRequest;