import request from "../axios";
import qs from "qs";

const aidRequest = {
  // 招生计划-专业下拉
  aidMajorDropDown(params) {
    return request({
      url: "/manage/tchaidclass/major/dropDown",
      method: "get",
      params
    });
  },

  // 招生计划-层次下拉
  aidEduCategoryDropDown(params) {
    return request({
      url: "/manage/tchaidclass/eduCategory/dropDown",
      method: "get",
      params
    });
  },
  // 招生计划-批次次下拉
  aidBatchDropDown(params) {
    return request({
      url: "/manage/tchaidclass/batch/dropDown",
      method: "get",
      params
    });
  }
};

export default aidRequest;
