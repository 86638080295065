import * as CryptoJS from 'crypto-js'
import { Message, Loading } from 'element-ui'
import IdentityCodeValid from './validateIdent'

/**
 *根据数据条数与每页多少条数据计算页数 
 * totalnum 数据条数
 * limit 每页多少条
 */

 function pageCount(totalnum, limit) {
  // return new Promise((resolve, reject) => {
  //   resolve(totalnum > 0 ? ((totalnum < limit) ? 1 : ((totalnum % limit) ? (parseInt(totalnum / limit) + 1) : (totalnum / limit))) : 0);
  // })
  return totalnum > 0 ? ((totalnum < limit) ? 1 : ((totalnum % limit) ? (parseInt(totalnum / limit) + 1) : (totalnum / limit))) : 0
}

/**
 * 获取当前系统时间
 */
 Date.prototype.Format = function (fmt) {
  var o = {
      "M+": this.getMonth() + 1, //月份 
      "d+": this.getDate(), //日 
      "H+": this.getHours(), //小时 
      "m+": this.getMinutes(), //分 
      "s+": this.getSeconds(), //秒 
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
      "S": this.getMilliseconds() //毫秒 
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
  if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

export const dateTime = new Date().Format("yyyy-MM-dd HH:mm:ss");
export const date = new Date().Format("yyyy-MM-dd");

/**
 * 时间戳转年月日
 * 例如：1563206400	return	2019-07-16
 */
const timestampReturnDate = (timestamp, type = 'date') => {
  if (!timestamp) return;
  let timestamps = Number(timestamp);
  let date = new Date(timestamps);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  let timeVal = y + "-" + m + "-" + d;
  if (type == "datetime") {
    timeVal = y + "-" + m + "-" + d + " " + "\xa0" + h + ":" + minute + ":" + second;
  }
  if (type == "timeYMDHM") {
    timeVal = y + "-" + m + "-" + d + " " + "\xa0" + h + ":" + minute;
  }
  if (type == "timeHM") {
    timeVal = h + ":" + minute;
  }
  if (type == "timeMD") {
    timeVal = m + "-" + d;
  }
  if (type == "dateYM") {
    timeVal = y + "-" + m;
  }
  if (type == "dateD") {
    timeVal = d;
  }
  if (type == "dateM") {
    timeVal = m;
  }
  if (type == "dateY") {
    timeVal = y;
  }
  return timeVal;
}

/**
 * 年月日转时间戳
 * 例如：Mon Nov 18 2019 10:56:36 GMT+0800 (中国标准时间)/2019-07-16/2019:07:16	return	1563206400
 * @param {String} time [时间]
 * @param {String} type [当天的00：00：00或23：59：59] default/start/end
 */
const dateReturnTimestamp = (time, type = 'default') => {
  let dVal = type == 'start' || type == 'default' ? 0 : (24 * 60 * 60 * 1000 - 1)
  var date = type == 'default' ? new Date(time) : new Date(time).setHours(0, 0, 0, 0) + dVal;
  // var date = new Date(time);
  return date.valueOf();
}

/**
 * 时间戳转时分秒(用于倒计时场景)
 * @param {String} data [时间戳][毫秒]
 * @param {String} type [事件展示格式]
 * @param {String} connect [时间合并符] 1. ':' 2. xx时xx分xx秒
 */
const toHHmmss = (data, type = 'Countdown', connect = 1) => {
  var time;
  var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((data % (1000 * 60)) / 1000);
  if (type == 'Countdown') {
    time = (hours < 10 ? ('0' + hours) : hours) + (connect == 1 ? ':' : '时') + (minutes < 10 ? ('0' + minutes) : minutes) + (connect == 1 ? ':' : '分') + (seconds < 10 ? ('0' + seconds) : seconds);
  } else if (type == 'timeShow') {
    time = (hours > 0 ? (hours < 10 ? ('0' + hours) : hours) + (connect == 1 ? ':' : '时') : '') + (minutes > 0 ? (minutes < 10 ? ('0' + minutes) : minutes) + (connect == 1 ? ':' : '分') : '') + (seconds > 0 ? (seconds < 10 ? ('0' + seconds) : seconds) + (connect == 1 ? '' : '秒') : '');
  }
  return time;
}

/**
 * 从零时到当前时间或24时的间隔时间数组
 * @param {Number} step [时间间隔]
 * @param {Number} endTime [截至时间]
 */
const timeSlot = (step, endTime = 24) => {
  var date = new Date()
  date.setHours('00')    // 时分秒设置从零点开始
  date.setSeconds('00')
  date.setUTCMinutes('00')
  // console.log(date.getHours())
  // console.log(date.getSeconds())
  // console.log(new Date(date.getTime()))
  
  var arr = [], timeArr = [];
  var slotNum = endTime*60/step   // 算出多少个间隔
  for (var f = 0; f < slotNum; f++) {   //  stepM * f = 24H*60M
      // arr.push(new Date(Number(date.getTime()) + Number(step*60*1000*f)))   //  标准时间数组
      var time = new Date(Number(date.getTime()) + Number(step*60*1000*f))  // 获取：零点的时间 + 每次递增的时间
      var hour = '', sec = '';
      time.getHours() < 10 ? hour = '0' + time.getHours() : hour = time.getHours()  // 获取小时
      time.getMinutes() < 10 ? sec = '0' + time.getMinutes() : sec = time.getMinutes()   // 获取分钟
      timeArr.push(hour + ':' + sec)
  }
  return timeArr
}

/*
 * 获取月份的第一天的零时零分零秒
 */
const getStartTime = (time) => {
  let date = new Date(time)
  date.setDate(1) // 将当前时间的日期设置成第一天
  let year= date.getFullYear()  // 得到当前年份 
  let month = date.getMonth()  + 1 // 得到当前月份（0-11月份，+1是当前月份）
  month  = month > 10 ? month :'0' + month // 补零
  let day  = date.getDate() // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
  let dateVal = dateReturnTimestamp(new Date(year + '-' + month + '-' + day))
  return dateVal
}

/*
 * 获取月份的最后一天的23时59分59秒
 */
const getEndTime = (time) => {
  let date = new Date(time)
  let year = date.getFullYear()  
  let month = date.getMonth() +1
 // 这里传入的是整数时间，返回的是下个月的第一天，因为月份是0-11
  let nextMonthFirthDay = new Date(year,month,1) // 下个月的第一天
  // console.log(nextMonthFirthDay)
  let oneDay = 1000*60 * 60 * 24 // 一天的时间毫秒数
  let endDay = new Date(nextMonthFirthDay - oneDay) 
  let day = endDay.getDate() // 本月最后一天
  let dateVal = new Date(year + '-' + month + '-' + day).setHours(0, 0, 0, 0)+(24 * 60 * 60 * 1000 - 1)
  return dateVal
}

/*
 * 获取今年一月一日到当月的最后一天的时间段
 */
const nowYearMon = (time) => {
  let end = new Date()
  let start = new Date()
  start = start.setMonth(start.getMonth() - new Date().getMonth())
  start = getStartTime(start)
  end = getEndTime(end)
  return [start, end]
}

/*
 * 文件下载--通过url下载
 */

const downloadFileUrl = (obj) => {
  console.log(obj);
  var element = document.createElement('a');
  element.setAttribute('href', obj.url);
  element.setAttribute('download', obj.fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

/*
 * 文件下载--通过文件流下载
 */

const downloadFile = (val,name) => {
  const blob = new Blob([val]);
  const fileName = name+".xlsx";
  const elink = document.createElement("a");
  elink.download = fileName;
  elink.style.display = "none";
  elink.href = URL.createObjectURL(blob);
  document.body.appendChild(elink);
  elink.click();
  URL.revokeObjectURL(elink.href); // 释放URL 对象
  document.body.removeChild(elink);
}

/*
 * 作用：文件上传
 */
const mediaFilesUpload = (file) => {
  return new Promise(resolve => {
    let reader = new FileReader();
    //转base64
    reader.onload = function(e) {
      resolve(e.target.result);
    };
    reader.readAsDataURL(file);
  })
}

/*
 * 作用：非媒体文件上传
 */
const officeFilesUpload = (data) => {
  return new Promise(resolve => {
    let file = data.file
    let forms = new FormData()
    forms.append('file', file);
    resolve(forms);
  })
}

// 图片上传oss
const uploadOss = (OSS, file, info) => {
  return new Promise((resolve, reject) => {
    let client = new OSS({
      accessKeyId: 'LTAI5tHS5twYJgCV447n9NnK',
      accessKeySecret: 'mD8N8NYbUx4EOPPZUynC2JX9JndqoQ',
      region: 'oss-cn-guangzhou',
      bucket: 'ykxt'
    });
    // ykxt-css
    const ym_date = new Date().Format("yyyyMM");
    const time = dateReturnTimestamp(new Date());
    let fileNameArr = file.name.split('.');
    let suffix = `${info}/${ym_date}/${fileNameArr[0]}_${time}.${fileNameArr[1]}`;
    // multipartUpload
    // client.multipartUpload(suffix, file, {
    //   parallel: 3, // 同时上传的分片数
    //   partSize: 1024 * 1024 * 20, // 每个分片大小(byte)
    //   // progress: onMultipartUploadProgress
    // }).then( res => {
    //   console.log(res,99999)
    //   resolve(decodeURIComponent(res.res.requestUrls[0]).split('?')[0]);
    // }).catch(err => {
    //   // console.log(err.response,985211)
    //   console.log(err)
    // })
    client.put(suffix, file).then( res => {
      console.log(res,99999)
      resolve(decodeURIComponent(res.res.requestUrls[0]));
    }).catch(err => {
      // console.log(err.response,985211)
      console.log(err)
    })
  })
}

function onMultipartUploadProgress(progress, checkpoint) {
  console.log(`${checkpoint.file.name} 上传进度 ${progress}`);
  // checkpoints[checkpoint.uploadId] = checkpoint;
  // 判断STS Token是否将要过期，过期则重新获取
  // const { Expiration } = credentials;
  // const timegap = 1;
  // if (Expiration && moment(Expiration).subtract(timegap, 'minute').isBefore(moment())) {
  //   console.log(`STS token will expire in ${timegap} minutes，uploading will pause and resume after getting new STS token`);
  //   if (ossClient) {
  //     ossClient.cancel();
  //   }
  //   await getCredential();
  //   await resumeMultipartUpload();
  // }
}

/**
 * @desc 防抖函数
 * @param {需要防抖的函数} func
 * @param {延迟时间} wait
 * @param {是否立即执行} immediate
 */
const debounce = (fn, wait, immediate) => {
  let timer;
  return function () {
      if (timer) clearTimeout(timer);
      if (immediate) {
          // 如果已经执行过，不再执行
          var callNow = !timer;
          timer = setTimeout(() => {
              timer = null;
          }, wait)
          if (callNow) {
              fn.apply(this, arguments)
          }
      } else {
          timer = setTimeout(() => {
              fn.apply(this, arguments)
          }, wait);
      }
  }
}

//节流
export function throttle(fn,delay){
  var lastTime;
  var timer;
  var delay = delay || 200;
  return function() {
    var args = arguments;
    // 记录当前函数触发的时间
    var nowTime = Date.now();
    if (lastTime && nowTime - lastTime < delay) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        // 记录上一次函数触发的时间
        lastTime = nowTime;
        // 修正this指向问题
        fn.apply(this, args);
      }, delay);
    }else{
      lastTime = nowTime;
      fn.apply(this, args);
    }
  }
}

// 设置手机号的验证规则
const regPhone = (value, type = 'ele') => {
  var isPhone = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
  var isMob=/^0\d{2,3}-?\d{7,8}$/;
  var isMobTel=/^[0-9]{0,4}-\d{1,2}-\d{5}$/;
  // const reg = /^1[3|4|5|6|7|8|9]\d{9}$/
  // const phoneReg = /^1[34578]\d{9}$/
  if (type == 'ele') {
    if (isMob.test(value) || isPhone.test(value) || isMobTel.test(value)) {
      return;
    } else {
      return new Error('请输入正确的电话')
    }
  } else {
    if (isMob.test(value) || isPhone.test(value) || isMobTel.test(value)) {
      return true;
    } else {
      Message({
        message: '请输入正确的电话号码',
        type: "warning"
      });
      return false;
    }
  }
  
}

// 设置邮箱的验证规则
const regEmail = (value, type = 'ele') => {
  let reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
  if (type == 'ele') {
    if (!reg.test(value)) {
      return new Error("请输入正确的邮箱");
    } else {
      return;
    }
  } else {
    if (!reg.test(value)) {
      Message({
        message: '请输入正确的邮箱',
        type: "warning"
      });
      return false;
    } else {
      return true;
    }
  }
}

// 设置邮箱的验证规则
const regEmailNew = (value) => {
  let reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
  if (!reg.test(value)) {
    Message({
      message: '请输入正确的邮箱',
      type: "warning"
    });
    return false;
  } else {
    return true;
  }
}

// 设置数据为数字的验证规则
const regNumber = (value) => {
  var regPos = /^\d+(\.\d+)?$/; //非负浮点数
  var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
  // if (!value) {
  //   return;
  // }
  if(regPos.test(value) || regNeg.test(value)){
      return true;
  }else{
      return false;
  }
}

// 校验数据为正整数
const regPositiveInteger = (value) => {
  var reg = /^[0-9]*[1-9][0-9]*$/
  if (reg.test(value))
    return true
  else
    return false
}

// 校验特殊符号
function checkSpecificKey(str) {
  var specialKey = "[`~!#$%^&*()-_=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'+"; 
  for (var i = 0; i < str.length; i++) {
    if (specialKey.indexOf(str.substr(i, 1)) != -1) {
      return false;
    }
  }
  return true;
}

// 不为特殊字符
const regSpecil = (value) => {
  if (!checkSpecificKey(value)) {
    return new Error("不能输入特殊符号");
  } else {
    return;
  }
}

// 中文校验
const regChinese = (value) => {
  if (/[\u4E00-\u9FA5]/g.test(value)) {
    return new Error("不能输入汉字");
  } else {
    // 验证通过
    return;
  }
}

// 判断密码满足大写字母，小写字母，数字和特殊字符，其中任意三种组合，且长度在8到16之间
const regNumletter = (value) => {
  // let reg= /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
  let reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,16}$/
  if (!reg.test(value)) {
    return new Error('密码必须是由8-16位大小写字母+数字组合+特殊字符其中任意三种或三种以上组合')
  }else{
    return;
  }
}

// 去除输入框内的空格
const clearSpace = (value) => {
  return value.replace(/\s*/g, "");
}

//判断该页面导出、导入、查询等按钮是否有权限，有权限显示，无权限隐藏
const isShowModle = (val) => {
  let arr = JSON.parse(sessionStorage.getItem('hasPerData'));
  let isShow = false;
  if (Array.isArray(val)) {
    try {
      val.forEach((item, index) => {
        if (arr.indexOf(item) != -1) {
          isShow = true;
          throw '终止';
        }
      })
    } catch(e) {}
  } else {
    arr.forEach((item, index) => {
      if (item == val) {
        isShow = true;
      }
    })
  }
  return isShow;
}

/**
 *加密处理
 */
 const encryption = (params) => {
  let {
    data,
    type,
    param,
    key
  } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(
        data,
        key, {
          iv: iv,
          mode: CryptoJS.mode.CFB,
          padding: CryptoJS.pad.NoPadding
        })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

/*
* 身份证校验
*/
const regIDCard = (value) => {
  if (IdentityCodeValid(value)) {
    // 验证通过
    return true;
  } else {
    // 格式不正确
    return false;
  }
}

// 上传文件判断文件名是否带有某些浏览器不能解析的字符
const checkUrl = (value) => {
  let pattern = new RegExp("[+%]")
  if (pattern.test(value)) {
    // 包含
    return true
  } else {
    // 不包含
    return false
  }
}

//封装函数 实现深浅拷贝  deep为true深拷贝 false浅拷贝
const copy = (oldObj,deep = true) => {
  let newObj = {}
  if (oldObj instanceof Array){
    newObj = []
  }
  for (let key in oldObj){
    let value = oldObj[key]
    if (deep && typeof value === "object" && value !== null) {
      //如果原对象的某个属性是引用类型数据，递归调用copy
      newObj[key] = copy(value,deep)
    } else {
      //如果原对象的某个属性是基本类型数据，直接将此属性赋值给新对象的相应属性
      newObj[key] = value
    }
  }
  return newObj
}

// 获取文件后缀
const getFileSuffix = (name) => {
  //获取最后一个.的位置
  let index = name.lastIndexOf(".");
  //获取后缀
  let ext = name.substr(index + 1);
  return ext
}

// 文件类型校验
const checkFileType = (name, type) => {
  let flag = true
  switch (type) {
    case 'img':
      flag = ['png', 'jpg', 'jpeg', 'gif'].includes(getFileSuffix(name))
      break;
    case 'excel':
      flag = ['xlsx', 'xls'].includes(getFileSuffix(name))
      break;
    case 'word':
      flag = ['doc', 'docx'].includes(getFileSuffix(name))
      break;
    case 'zip':
      flag = ['zip', 'rar'].includes(getFileSuffix(name))
      break;
    case 'video':
      flag = ['mp4', 'rmvb', 'avi', 'flv', 'swf', 'mkv', 'ogg'].includes(getFileSuffix(name))
      break;
    default:
      flag = !(['asp','php','cgi','aspx','jsp','html'].includes(getFileSuffix(name)))
  }
  return flag
}

export default {
  pageCount,
  timestampReturnDate,
  dateReturnTimestamp,
  toHHmmss,
  timeSlot,
  getStartTime,
  getEndTime,
  nowYearMon,
  downloadFileUrl,
  downloadFile,
  mediaFilesUpload,
  officeFilesUpload,
  uploadOss,
  debounce,
  throttle,
  regPhone,
  regEmail,
  regEmailNew,
  regNumber,
  regPositiveInteger,
  regSpecil,
  regChinese,
  regNumletter,
  clearSpace,
  encryption,
  isShowModle,
  regIDCard,
  checkUrl,
  copy,
  getFileSuffix,
  checkFileType
}