import request from '../axios'

const scoreRuleRequest = {
  // 学校课程设置-得分设置
  getScoreFlag(params) {
    return request({
      url: '/manage/school/course/course/site/scoreFlag',
      method: 'get',
      params
    })
  },
}

export default scoreRuleRequest;