let system = null;
let model = null;
let sign = '';

// 生成32位UUID
function getUuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 32; i++) {
  s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23];
  var uuid = s.join("");
  return uuid;
}

// function OSnow(){
//   var agent = navigator.userAgent.toLowerCase();
//   var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
//   if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
//     system = 'win32';
//   }
//   if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
//     system = 'win64';
//   }
//   if(isMac){
//     system = 'mac'
//   }
// }

// OSnow();

// model = system == 'win32' || system == 'win64' ? 'windowsTerminal' : 'macTerminal'

function updateSignObj() {
  sign = ''
  let signObj = {
    // 'SIGN-APPLICATION-VERSION': '1.0.1', // 应用版本号
    // 'SIGN-CLIENT-MODEL': model, // 客户端型号
    // 'SIGN-CLIENT-SYSTEM': system, // 客户端操作系统版本
    // 'SIGN-CLIENT-TYPE': 'web', // 客户端类型(android,ios,web)
    'SIGN-REQUEST-ID': getUuid(),
    'SIGN-TIMESTAMP': (new Date()).valueOf(), // 时间戳(毫秒级)
    // 'SIGN': sign // 签名
  }
  
  for (let key in signObj) {
    sign = sign + signObj[key] + ',';
  }
  sign = sign.substring(0, sign.length - 1) + ',dd2438c6-c843-4c0c-ac7f-56be937700f1';
  signObj.SIGN = hex_md5(sign);
  return signObj
}

export default updateSignObj;