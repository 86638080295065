import axios from 'axios';
import QS from 'qs';
import { Message, Loading } from 'element-ui'
import router from '../router'
import request from "./request";
import updateSignObj from './signatureVerification'
// import { serialize } from '@/util/util'
// import { getStore } from '@/util/store'

let requestNum = 0; // 当前模块接口请求次数

// 接口提示排除接口
let hideRequestTipsArr = [
  '/code/check'
]

// 数组内接口请求不会转圈圈
let hideLodingArr = ['/manage/platform/online/count']

// 请求超时时间
// axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.withCredentials = true;
// 请求头新增验签字段
function reqHeaderAddWord(config) {
  let signObj = updateSignObj()
  for (let key in signObj) {
    config.headers[key] = signObj[key];
  }
}

let url_origin = location.origin;

// 接口请求基准路径配置
axios.defaults.baseURL = process.env.NODE_ENV == "development" ? '/' : url_origin

// NProgress Configuration
// NProgress.configure({
//   showSpinner: false
// })

let loading;
function startLoading() {    //使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: '拼命加载中...',
    background:'rgba(0,0,0,.5)',
  })
}
function endLoading() {    //使用Element loading-close 方法
  loading.close()
}

// 请求拦截器
axios.interceptors.request.use(config => {
  requestNum++;
  // NProgress.start() // start progress bar
  // const TENANT_ID = JSON.parse(sessionStorage.loginData).user_info.tenantId;
  const isToken = (config.headers || {}).isToken === false
  var token = sessionStorage.token;
  if (token && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + token// token
  }
  config.headers['TENANT-ID'] = '0'
  // 每次请求更新验签字段
  reqHeaderAddWord(config)
  // startLoading();
  if (!hideLodingArr.includes(config.url)) {
    window.$loading.show();
  }
  // if (TENANT_ID) {
  //   config.headers['TENANT-ID'] = TENANT_ID // 租户ID
  // }

  // headers中配置serialize为true开启序列化
  // if (config.method === 'post' && config.headers.serialize) {
  //   config.data = serialize(config.data)
  //   delete config.data.serialize
  // }

  // if (config.method === 'get') {
  //   config.paramsSerializer = function (params) {
  //     return QS.stringify(params, { arrayFormat: 'repeat' })
  //   }
  // }

  return config
}, error => {
  //  1.判断请求超时  再重复请求一次
  // if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
  //     return service.request(originalRequest);
  // }
  requestNum--;
  if(requestNum == 0) window.$loading.hide();
  return Promise.error(error);
})


//axios响应拦截

// 是否正在刷新token
let isRefreshing = false;
// 重试队列 每一项都是一个待执行待函数
let requests = [];
axios.interceptors.response.use((response) => {
  requestNum--;
  if (response.data.code == 1 && !hideRequestTipsArr.includes(response.config.url)) {
    Message({
      message: response.data.msg,
      type: "warning"
    });
  }
  // endLoading();
  if(requestNum == 0) window.$loading.hide();
  return response;
}, error => {
  requestNum--;
  if (error.response.status == 401){
      if (!isRefreshing) {
        isRefreshing = true;
        request.refreshToken(sessionStorage.getItem("refresh_token")).then(res =>{
          if (res.status == 200){
            let loginData = res.data
            sessionStorage.setItem("token", loginData.access_token);
            sessionStorage.setItem("refresh_token",loginData.refresh_token)
            sessionStorage.setItem("loginData", JSON.stringify(loginData));
            requests.length > 0 && requests.map((cb) => {
              cb();
            });
            requests = [];  //注意要清空
            error.config.headers.Authorization = sessionStorage.getItem('token');
            // location.reload();
            return axios.request(error.config);
          }
        }).catch(err => {
          router.push({ path: '/Login' })
          sessionStorage.clear();
        }).finally(() => {
          isRefreshing = false;
        });
      }else {
        // 正在刷新token ,把后来的接口缓冲起来
        return new Promise((resolve) => {
          requests.push(() => {
            error.config.headers.Authorization = sessionStorage.getItem('token');
            error.config.headers.withCredentials = true;
            resolve(axios.request(error.config));
          });
        })
      }
  }

  if (error.response.status != 401){
    if (error.response.data.code == 1) {
      Message({
        message: error.response.data.msg,
        type: "warning"
      });
    }
  }
  // endLoading();
  if(requestNum == 0) window.$loading.hide();
  Promise.reject(error)
});

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Boolean} sdt [是否加密,加密传true，否者传false]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, dataType = 'json', responseType = 'json') {
  return new Promise((resolve, reject) => {
    params = dataType == 'json' ? params : QS.stringify(params);
    responseType = responseType == 'blob' ? 'blob' : responseType;
    axios.post(url, params, { responseType })
      .then(res => { resolve(res.data); })
      .catch(err => { reject(err); console.log(err) })
  });
}
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Boolean} sdt [是否加密,加密传true，否者传false]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params = {}, dataType = 'json', responseType = 'json') {
  return new Promise((resolve, reject) => {
    params = dataType == 'json' ? params : QS.stringify(params);
    responseType = responseType == 'blob' ? 'blob' : responseType;
    axios.get(url, {params}, { responseType })
      .then(res => { resolve(res.data); })
      .catch(err => { reject(err.data); console.log(err) })
  });
}
/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Boolean} sdt [是否加密,加密传true，否者传false]
 * @param {Object} params [请求时携带的参数]
 */
 export function deleteWay(url, params = {}, dataType = 'json', responseType = 'json') {
  return new Promise((resolve, reject) => {
    params = dataType == 'json' ? params : QS.stringify(params);
    responseType = responseType == 'blob' ? 'blob' : responseType;
    axios.delete(url, {params}, { responseType })
      .then(res => { resolve(res.data); })
      .catch(err => { reject(err); console.log(err) })
  });
}
/**
 * from表单提交
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function formPost(url, params = {}) {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append('file', params);
    axios({
      url,
      method: "POST",
      headers: { "content-type": "multipart/form-data" },
      data: form
    })
      .then(res => { resolve(res.data); })
      .catch(err => { reject(err.data); console.log(err) })
  });
}

export default axios;
