import request from '../axios'
import qs from 'qs'

const degreeRequest = {
  // 通过id查询
  queryByidToDegreeClass(params) {
    return request({
      url: `/manage/tchforeignclass/${params.id}`,
      method: 'get'
    })
  },
  // 删除
  deleteDegreeClass(params) {
    return request({
      url: `/manage/tchforeignclass/${params.id}`,
      method: 'delete'
    })
  },
  // 设置课程
  setUpDegreeClass(data) {
    return request({
      url: '/manage/tchforeignclass/configureResources',
      method: 'delete',
      data
    })
  },
  // 课程列表
  degreeClassList(params) {
    return request({
      url: '/manage/tchforeignclass/listCourse',
      method: 'get',
      params
    })
  },
  // 分页查询
  degreeClassToPage(params) {
    return request({
      url: '/manage/tchforeignclass/page',
      method: 'get',
      params
    })
  },
  // 发布
  releaseDegreeClass(params) {
    return request({
      url: `/manage/tchforeignclass/release/${params.id}/${params.status}`,
      method: 'put'
    })
  },
  // 新增学位外语班级表
  addDegreeClass(params) {
    return request({
      url: '/manage/tchforeignclass/saveOrUpdate',
      method: 'post',
      params
    })
  },
  // 修改学位外语班级表
  editDegreeClass(data) {
    return request({
      url: '/manage/tchforeignclass/update',
      method: 'put',
      data
    })
  },
  // 获取班级选中的课件
  selectedClassDegreeCourse(params, path) {
    return request({
      url: `/manage/tchforeignclass/courseware/getCourseware/${path.foreignClassId}`,
      method: 'get',
      params
    })
  },
  // 获取助学课件id列表
  classDegreeCourseIds(params) {
    return request({
      url: `/manage/tchforeignclass/courseware/getForeignCoursewareList/${params.foreignClassId}`,
      method: 'get'
    })
  },
  // 获取课件
  classDegreeCourseList(params, path) {
    return request({
      url: `/manage/tchforeignclass/courseware/getCoursewarePageByCourseId/${path.foreignClassId}`,
      method: 'delete',
      params
    })
  },
  // 添加资源
  addClassDegreeResources(data) {
    return request({
      url: '/manage/tchforeignclass/resources/add',
      method: 'put',
      data
    })
  },
  // 移除资源
  deleteClassDegreeResources(data) {
    return request({
      url: '/manage/tchforeignclass/resources/delete',
      method: 'delete',
      data
    })
  },
  // 获取班级选中的专项练习
  selectedClassDegreeExercises(params, path) {
    return request({
      url: `/manage/tchforeignclass/getYkSpecial/${path.foreignClassId}`,
      method: 'get',
      params
    })
  },
  // 获取题库
  classDegreeExercises(params, path) {
    return request({
      url: `/manage/tchforeignclass/getQuestionBank/${path.foreignClassId}`,
      method: 'get',
      params
    })
  },
  // 获取试卷
  classDegreeExamination(params) {
    return request({
      url: '/manage/tchforeignclass/examPaper/getExamPaper',
      method: 'get',
      params
    })
  },
  // 获取学生
  classDegreeStudent(params) {
    return request({
      url: '/manage/tchforeignclass/getStudent',
      method: 'get',
      params
    })
  },
  // 关闭权限
  closeDegreeStuJurisdic(params) {
    return request({
      url: '/manage/tchforeignclass/turnOffPermissions',
      method: 'delete',
      params
    })
  },
  // 切换班级
  changeDegreeStuClass(params) {
    return request({
      url: '/manage/tchforeignclass/switchClass',
      method: 'put',
      params
    })
  },
  // 学位班级
  degreeStuClassList() {
    return request({
      url: '/manage/tchforeignclass/getForeignClassList',
      method: 'get'
    })
  },
}

export default degreeRequest;