<template>
  <div class="Loading" v-if="show">
    <div class="load_cont">
      <section><span class="loader-11"></span></section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    show: Boolean,
  },
}
</script>

<style lang="less">
  .Loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(51, 51, 51, 0.2);
    .load_cont {
      width: 300px;
      height: 60px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      section {
        text-align: center;
      }
      .loader-11 {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        border: 3px solid;
        border-color: #FFF #FFF transparent transparent;
        -webkit-animation: rotation 1s linear infinite;
                animation: rotation 1s linear infinite;
      }
      .loader-11:after, .loader-11:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent #FF3D00 #FF3D00;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        -webkit-animation: rotationBack 0.5s linear infinite;
        animation: rotationBack 0.5s linear infinite;
        transform-origin: center center;
      }
      .loader-11:before {
        width: 32px;
        height: 32px;
        border-color: #FFF #FFF transparent transparent;
        -webkit-animation: rotation 1.5s linear infinite;
        animation: rotation 1.5s linear infinite;
      }
      @-webkit-keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
</style>