// 全局接口
export default {
  getQuestionType: {
    1: "单选题",
    2: "多选题",
    3: "不定项选择题",
    4: "判断题",
    5: "填空题",
    6: "问答题",
    7: "材料分析题",
    8: "配伍题",
  },
  getEduCategory: {
    0: "专升本",
    1: "高起本",
    2: "高起专",
    3: "中专"
  },
  courseNature: {
    1: "选修",
    2: "必修",
    3: "主干课程",
    0: "无",
  },
  courseType: {
    1: "公共课",
    2: "专业课",
    3: "专业基础课",
    4: "实践",
    0: "无",
  },
  // 打印类型  1 录取通知书 2成绩单 3学籍卡 4 毕业证书 5 学位证
  printType: {
    1: "录取通知书",
    2: "成绩单",
    3: "学籍卡",
    4: "毕业证书",
    0: "学位证",
  },
  // 打印尺寸  1 A4 2 A3
  printSize: {
    A4: "A4",
    A3: "A3",
    A5: "A5",
  },
  //学习形式(0函授1脱产2业余3全日制)
  getStuType: {
    1: "脱产",
    2: "业余",
    0: "函授",
    3: "全日制",
  },
  liberalScience: {
    1: "理科",
    2: "文科"
  },
  learningPermissions: {
    0: "已开通",
    1: "待开通",
    2: "审核中",
    3: "已关闭"
  },
  closeLarningStatus: {
    0: "待审核",
    1: "审核通过",
    2: "审核不通过"
  },
  // 直播状态
  liveStatusData: {
    0: "未开始",
    1: "直播中",
    2: "已结束"
  },
  // 时间维度
  timeStatisticType: {
    1: "按日统计",
    2: "按月统计"
  },
  // 是否开发票(0否1是)
  invoiceFlagData: {
    0: "否",
    1: "是"
  },
  // 开票状态(0不开票1申请开票2已开票)
  invoiceStatusData: {
    0: "不开票",
    1: "申请开票",
    2: "已开票"
  },
  // 发票种类(1电子发票2纸质发票)
  invoiceKindData: {
    1: "电子发票",
    2: "纸质发票"
  },
  // 发票类型(1增值税专用发票2普通发票)
  invoiceTypeData: {
    1: "增值税专用发票",
    2: "普通发票"
  },
  // 抬头类型(1个人非企业 2.企业单位)
  headTypeData: {
    1: "个人非企业",
    2: "企业单位"
  },
  // 开票方式(1合并统开2订单分开)
  invoicingMethodData: {
    1: "合并统开",
    2: "订单分开"
  },
  // 有无客服(0无1有)
  cusSetData: {
    0: "无",
    1: "有"
  },
  // 省份默认客服(0关1开)
  provinceFlagData: {
    0: "否",
    1: "是"
  },
  // 客服形式(1个人2组)
  cusTypeData: {
    1: "个人客服",
    2: "客服组"
  },
  // 学校审核状态
  schAuditData: {
    0: "未审核",
    1: "审核通过",
    2: "审核不通过"
  },
  // 费用类型(0免费1收费3演示)
  payAuditData: {
    0: "免费",
    1: "收费",
    3: "演示"
  },
  // 费用类型(0免费1收费2仅重新开通权限3演示)
  feeTypeData: {
    0: "免费",
    1: "收费",
    2: "仅重新开通权限",
    3: "演示"
  },
  // 教学计划状态(0 可用，1 禁用)
  teachPlanStatusData: {
    0: '可用',
    1: '禁用'
  },
  // 得分显示(1 分值，2 等级)
  scoreTypeData: {
    1: '分值',
    2: '等级'
  },
  // 学校性质(1商用 2演示)
  schoolNatureData: {
    1: '商用',
    2: '演示'
  },
  // 支付平台(0支付宝1微信2银行卡)
  spTypeData: {
    0: '支付宝',
    1: '微信',
    2: '银行卡'
  },
  // 缴费状态(0未支付，1已支付2缴费异常3未结清4已退款)
  statusData: {
    0: '未支付',
    1: '已支付',
    2: '缴费异常',
    3: '未结清',
    4: '已退款'
  },
  // 学习权限(0已开通1待开通2审核中3已关闭4已注销)
  learningPermissionsData: {
    0: '已开通',
    1: '待开通',
    2: '审核中',
    3: '已关闭',
    4: '已注销'
  },
  // 缴费方式(0个人缴费1学校统交)
  payTypeData: {
    0: '个人缴费',
    1: '学校统交'
  },
  // 退款状态
  // refundStatusData: {
  //   0: '已退款',
  //   1: '无需退款',
  //   2: '待退款'
  // },
  msgTypeData: {
    1: '助学开通账号',
    2: '招生审核通过',
    3: '招生审核不通过',
    4: '验证码',
    5: '招生补充材料审核通过',
    6: '招生补充材料审核不通过'
  }
};
