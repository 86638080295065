import way from './encapsulation'
import { Message } from 'element-ui'
import store from '../store'
const OSS = require('ali-oss');

let credentials = null; // STS凭证
let client = null; // oss客户端实例
let suffix = null; // 文件名
let partSize = 1024 * 1024 * 20; // 每个分片大小(byte)
let parallel = 3; // 同时上传的分片数
let checkpoints = {}; // 所有分片上传文件的检查点
let showFileName = null;
let baseUrl = null; // 基准路径

let ossInfo = {
  accessKeyId: 'LTAI5tHS5twYJgCV447n9NnK',
  accessKeySecret: 'mD8N8NYbUx4EOPPZUynC2JX9JndqoQ',
  region: 'oss-cn-guangzhou', // oss服务区域名称
  bucket: 'ykxt'
}

// 根据传入的type,对oss配置信息进行对应的修改
function changeOssInfo(type) {
  switch (type) {
    case 1:
      ossInfo.bucket = 'ykxt';
      baseUrl = 'https://files.youkexuetang.cn/'
      break;
    case 2:
      ossInfo.bucket = 'ykxt-css'
      baseUrl = 'https://image.youkexuetang.cn/'
      break;
    case 3:
      ossInfo.bucket = 'autoface'
      ossInfo.region = 'oss-cn-shanghai'
  }
}

// excel文件上传
const excelFileUploadOss = (file, info, type = 1, name = null) => {
  let index = file.name.lastIndexOf(".");
  let ext = file.name.substr(index + 1);
  if (ext == 'xlsx' || ext == 'xls') { 
    return uploadOss(file, info, type, name)
  } else {
    Message({
      message: '文件格式不正确,请传excel文件',
      type: "warning"
    });
  }
}

// word文件上传
const wordFileUploadOss = (file, info, type = 1, name = null) => {
  let index = file.name.lastIndexOf(".");
  let ext = file.name.substr(index + 1);
  if (ext == 'doc' || ext == 'docx') { 
    return uploadOss(file, info, type, name)
  } else {
    Message({
      message: '文件格式不正确,请传word文档',
      type: "warning"
    });
  }
}

// zip文件上传封装
const zipFileUploadOss = (file, info, type = 1, name = null) => {
  let index = file.name.lastIndexOf(".");
  let ext = file.name.substr(index + 1);
  if (ext == 'zip' || ext == 'rar') {
    return uploadOss(file, info, type, name)
  } else {
    Message({
      message: '文件格式不正确,请传zip压缩文件',
      type: "warning"
    });
  }
}

// img文件上传封装
const imgFileUploadOss = (file, info, type = 1, name = null) => {
  let index = file.name.lastIndexOf(".");
  let ext = file.name.substr(index + 1);
  if (ext == 'png' || ext == 'jpg' || ext == 'gif') {
    return uploadOss(file, info, type, name)
  } else {
    Message({
      message: '文件格式不正确,请传图片',
      type: "warning"
    });
  }
}

// video文件上传封装
const videoFileUploadOss = (file, info, type = 1, name = null) => {
  let index = file.name.lastIndexOf(".");
  let ext = file.name.substr(index + 1);
  if (ext == 'mp4' || ext == 'rmvb' || ext == 'avi' || ext == 'flv' || ext == 'swf' || ext == 'mkv' || ext == 'ogg') {
    return uploadOss(file, info, type, name)
  } else {
    Message({
      message: '文件格式不正确',
      type: "warning"
    });
  }
}

/**
 * 文件上传ali-oss
 * @param {Object} file [文件数据, 如file，blob]
 * @param {String} info [功能模块, 如sysstudent]
 * @param {Number} type [oss上传用途，1.项目业务文件上传，2.项目静态资源存储, 3.oss服务区域替换]
 * @param {String} name [上传文件命名，默认null，采用oss默认命名规范]
 */
const uploadOss = (file, info, type = 1, name = null) => {
  if (way.checkUrl(file.name)) {
    Message({
      message: '文件名内不能带有+、%',
      type: "warning"
    });
    return;
  }
  return new Promise((resolve, reject) => {
    changeOssInfo(type);
    client = new OSS(ossInfo);
    const ym_date = new Date().Format("yyyyMM");
    const time = way.dateReturnTimestamp(new Date());
    let fileNameArr = file.name.split('.');
    suffix = name ? `${name}.${fileNameArr[1]}` : `${fileNameArr[1]}/${info}/${ym_date}/${fileNameArr[0]}_${time}.${fileNameArr[1]}`;
    //获取最后一个.的位置
    let index = file.name.lastIndexOf(".");
    //获取后缀
    let ext = file.name.substr(index + 1);
    // 获取文件名，不包括文件后缀名
    let fName = file.name.substr(0, index);
    showFileName = fName;
    if (fName.length > 30) {
      showFileName = fName.substring(0, 31) + '···.' + ext;
    } else {
      showFileName = fName + '.' + ext;
    }
    let ossObj = {
      fileName: file.name,
      showFileName,
      excelUrl: null
    }
    // 如果文件大小小于分片大小，使用普通上传，否则使用分片上传
    if (file.size >= partSize) {
      // 分片上传
      mulUpload(file).then( res => {
        console.log(res, 99999)
        ossObj.excelUrl = type != 3 ? decodeURIComponent(baseUrl + res.name) : res.url
        resolve(ossObj);
      }).catch(err => {
        Message({
          message: err,
          type: "warning"
        });
      });
    } else {
      // 普通上传
      commonUpload(file).then( res => {
        console.log(res, 888888)
        ossObj.excelUrl = type != 3 ? decodeURIComponent(baseUrl + res.name) : res.url
        resolve(ossObj);
      }).catch(err => {
        Message({
          message: err,
          type: "warning"
        });
      });
    }
  })
}

const uploadBlobOss = (blob, info, type = 1) => {
  return new Promise((resolve, reject) => {
    changeOssInfo(type);
    client = new OSS(ossInfo);
    const ym_date = new Date().Format("yyyyMM");
    const time = way.dateReturnTimestamp(new Date());
    let fileNameArr = blob.type == "image/jpeg" ? 'jpg' : 'png';
    suffix = `${info}/${ym_date}/${fileNameArr[0]}_${time}.${fileNameArr}`;
    let ossObj = {
      fileName: null,
      showFileName,
      excelUrl: null
    }
    // 如果文件大小小于分片大小，使用普通上传，否则使用分片上传
    if (blob.size >= partSize) {
      // 分片上传
      mulUpload(blob).then( res => {
        console.log(res, 99999)
        ossObj.excelUrl = type != 3 ? decodeURIComponent(baseUrl + res.name) : res.url
        resolve(ossObj);
      }).catch(err => {
        Message({
          message: err,
          type: "warning"
        });
      });
    } else {
      // 普通上传
      commonUpload(blob).then( res => {
        console.log(res, 888888)
        ossObj.excelUrl = type != 3 ? decodeURIComponent(baseUrl + res.name) : res.url
        resolve(ossObj);
      }).catch(err => {
        Message({
          message: err,
          type: "warning"
        });
      });
    }
  })
}

// 普通上传
const commonUpload = (file) => {
  return client.put(suffix, file);
}

// 分片上传
const mulUpload = (file) => {
  store.commit('changeIsProgress', true);
  return client.multipartUpload(suffix, file, {
    parallel, // 同时上传的分片数
    partSize, // 每个分片大小(byte)
    progress: onMultipartUploadProgress
  })
}

// 断点续传
function resumeMultipartUpload() {
  // Object.values(checkpoints).forEach((checkpoint) => {
  //   const { uploadId, file, name } = checkpoint;
  //   ossClient.multipartUpload(uploadId, file, {
  //     parallel,
  //     partSize,
  //     progress: onMultipartUploadProgress,
  //     checkpoint
  //   }).then(result => {
  //     console.log('before delete checkpoints === ', checkpoints);
  //     delete checkpoints[checkpoint.uploadId];
  //     console.log('after delete checkpoints === ', checkpoints);
  //     const url = `http://${bucket}.${region}.aliyuncs.com/${name}`;
  //     console.log(`Resume multipart upload ${file.name} succeeded, url === `, url)
  //   }).catch(err => {
  //     console.log('Resume multipart upload failed === ', err);
  //   });
  // });
}

// 分片上传进度
function onMultipartUploadProgress(progress, checkpoint) {
  console.log(`${checkpoint.file.name} 上传进度 ${progress}`);
  store.commit('changeProgress', progress.toFixed(2));
  if (progress == 1) {
    setTimeout(() => {
      store.commit('changeIsProgress', false);
    }, 1000);
  }
  // checkpoints[checkpoint.uploadId] = checkpoint;
  // 判断STS Token是否将要过期，过期则重新获取
  // const { Expiration } = credentials;
  // const timegap = 1;
  // if (Expiration && moment(Expiration).subtract(timegap, 'minute').isBefore(moment())) {
  //   console.log(`STS token will expire in ${timegap} minutes，uploading will pause and resume after getting new STS token`);
  //   if (ossClient) {
  //     ossClient.cancel();
  //   }
  //   await getCredential();
  //   await resumeMultipartUpload();
  // }
}

export default {
  excelFileUploadOss,
  zipFileUploadOss,
  imgFileUploadOss,
  videoFileUploadOss,
  uploadOss,
  uploadBlobOss,
  wordFileUploadOss
}