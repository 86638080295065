/*
 * @Description: 全局组件注册
 */


import Keydown from "@/components/commonModule/keydown"

function plugin(Vue) {
  if (plugin.installed) {
    return
  }
  Vue.component('Keydown', Keydown)
}

export default plugin
