import request from '../axios'
import qs from 'qs'

const aidRequest = {
  // 查询助学分类
  aidClassificationData(params) {
    return request({
      url: '/manage/tchaidclass/getAidClassification',
      method: 'get',
      params
    })
  },

  // 查询班级管理
  aidClassManagementData(params) {
    return request({
      url: '/manage/tchaidclass/getAidClassManagement',
      method: 'get',
      params
    })
  },

  // 新增修改
  changeClass(data) {
    return request({
      url: '/manage/tchaidclass/saveOrUpdate',
      method: 'post',
      data
    })
  },

  // 发布
  publishClass(params) {
    return request({
      url: `/manage/tchaidclass/release/${params.id}/${params.status}`,
      method: 'put'
    })
  },

  // 通过id删除助学班级表
  deleteAidClass(params) {
    return request({
      url: `/manage/tchaidclass/${params.id}`,
      method: 'delete'
    })
  },

  // 查询班级下的助学学生
  queryClassStudent(params) {
    return request({
      url: '/manage/tchaidclass/getAidClassStuPage',
      method: 'get',
      params
    })
  },

  // 存在争议列表
  disputeList(params) {
    return request({
      url: '/manage/tchaidclass/dispute/list',
      method: 'get',
      params
    })
  },

  // 关闭账号
  closeAccount(params) {
    return request({
      url: '/manage/tchaidclass/aidStudent/closeAccount',
      method: 'put',
      params
    })
  },

  // 重置助学学生密码
  resetStuPass(params) {
    return request({
      url: `/admin/user/aid/resetPassword/${params.username}`,
      method: 'put'
    })
  },

  // 获取课件
  coursewareChangeList(params) {
    return request({
      url: '/manage/tchaidclass/courseware/getCourseware',
      method: 'get',
      params
    })
  },

  // 获取班级选中的课件
  coursewareList(params, aidClassId) {
    return request({
      url: `/manage/tchaidclass/courseware/getCourseware/${aidClassId}`,
      method: 'get',
      params
    })
  },

  // 移除课件
  deleteCourseWare(params) {
    return request({
      url: `/manage/tchaidclass/courseware/${params.aidCourseWareId}`,
      method: 'delete'
    })
  },

  // 添加课件
  addCourseWare(data) {
    return request({
      url: '/manage/tchaidclass/courseware/add',
      method: 'put',
      data
    })
  },

  // 获取班级选中的专项练习
  ykSpecialList(params, aidClassId) {
    return request({
      url: `/manage/tchaidclass/getYkSpecial/${aidClassId}`,
      method: 'get',
      params
    })
  },

  // 获取题库
  getYkSpecialList(params, aidClassId) {
    return request({
      url: `/manage/tchaidclass/getQuestionBank/${aidClassId}`,
      method: 'get',
      params
    })
  },

  // 添加专项练习
  addSpecial(data) {
    return request({
      url: '/manage/tchaidclass/special/add',
      method: 'put',
      data
    })
  },

  // 移除专项练习
  deleteSpecial(params) {
    return request({
      url: '/manage/tchaidclass/special/delete',
      method: 'delete',
      params
    })
  },

  // 获取试卷
  examPaperList(params, aidClassId) {
    return request({
      url: '/manage/tchaidclass/examPaper/getExamPaper',
      method: 'get',
      params
    })
  },

  // 添加试卷
  addExamPaperList(data) {
    return request({
      url: '/manage/tchaidclass/examPaper/add',
      method: 'put',
      data
    })
  },

  // 移除试卷
  deleteExamPaperList(params) {
    return request({
      url: '/manage/tchaidclass/examPaper/delete',
      method: 'delete',
      params
    })
  },

  // 课程管理 学校端-获取课件预览树数据
  getScCourseTree(data) {
    return request({
      url: '/manage/syscourse/courseTree?coursewareId=' + data,
      method: 'get',
      data
    })
  },

  // 获取课件播放地址
  getPlayAuth(params) {
    return request({
      url: '/manage/syscourse/vod',
      method: 'get',
      params
    })
  },

  // 获取助学课程列表
  courseAidList(params) {
    return request({
      url: `/manage/tchaidclass/getAidCoursesList/${params.aidClassId}`,
      method: 'get'
    })
  },

  // 获取助学课件id列表
  getAidCourseIds(params) {
    return request({
      url: `/manage/tchaidclass/getAidCoursewareList/${params.aidClassId}`,
      method: 'get'
    })
  },

  // 直播管理
  // 接入直播
  insertLive(data) {
    return request({
      url: '/manage/aidLive/add',
      method: 'post',
      data
    })
  },
  // 删除直播
  delateLive(params) {
    return request({
      url: '/manage/aidLive/delete',
      method: 'delete',
      params
    })
  },
  // 直播详情
  liveInfoDetails(params) {
    return request({
      url: '/manage/aidLive/details',
      method: 'get',
      params
    })
  },
  // 列表查询(分页)
  liveInfoData(params) {
    return request({
      url: '/manage/aidLive/list',
      method: 'get',
      params
    })
  },
  // 修改直播
  updateLiveInfo(data) {
    return request({
      url: '/manage/aidLive/update',
      method: 'put',
      data
    })
  },
}

export default aidRequest;