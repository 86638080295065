import request from '../axios'
import qs from 'qs'

const roleRequest = {
  // 学校角色管理-角色分页列表
  schoolRoleList(params) {
    return request({
      url: '/admin/role/pt/school/page',
      method: 'get',
      params
    })
  },
  // 学校角色管理-角色下拉接口
  schoolRoleDownList(params) {
    return request({
      url: '/admin/role/pt/school/roleDown',
      method: 'get',
      params
    })
  },
  // 学校角色管理-新增角色表
  addSchoolRole(data) {
    return request({
      url: '/admin/role/pt/school/add',
      method: 'post',
      data
    })
  },
  // 学校角色管理-端修改角色
  updateSchoolRole(data) {
    return request({
      url: '/admin/role/pt/school/update',
      method: 'put',
      data
    })
  },
  // 学校角色管理-平台端禁用角色
  prohibitSchoolRole(params) {
    return request({
      url: `/admin/role/pt/school/disable/${params.id}`,
      method: 'put'
    })
  },
  // 学校角色管理-启用角色
  useSchoolRole(params) {
    return request({
      url: `/admin/role/pt/school/enable/${params.id}`,
      method: 'put'
    })
  },
  // 学校角色管理-删除角色
  deleteSchoolRole(params) {
    return request({
      url: `/admin/role/pt/school/del/${params.id}`,
      method: 'delete'
    })
  },
  // 获取菜单(树形展示)
  schoolRoleMenuTree(params) {
    return request({
      url: '/admin/role/getRoleMenuTree',
      method: 'get',
      params
    })
  },
}

export default roleRequest;