// import cai from "./requestModules/cai";
// import index from "./requestModules/index";
// import common from "./requestModules/common";
// import ueditor from "./requestModules/ueditor";
// import system from "./requestModules/system";
// import aidManagement from "./requestModules/aidManagement";
// import degree from "./requestModules/degree";
// import role from "./requestModules/role";
// import orderManage from "./requestModules/orderManage";
// import aidClass from "./requestModules/aidClass";
// import job from "./requestModules/job";
// import platformMonitor from "./requestModules/platformMonitor";
// import course from "./requestModules/course";
// import statistics from "./requestModules/statistics";
// import customerService from "./requestModules/customerService";

// 批量引入requestModules文件夹内的js文件
const modulesFiles = require.context("./requestModules", true, /\.js$/)
const cmps = {};

modulesFiles.keys().forEach((fileName) => {
  let cmp = modulesFiles(fileName).default;
  Object.assign(cmps, cmp)
});

export default {
  // ...cai,
  // ...index,
  // ...common,
  // ...ueditor,
  // ...system,
  // ...aidManagement,
  // ...degree,
  // ...role,
  // ...orderManage,
  // ...aidClass,
  // ...job,
  // ...platformMonitor,
  // ...course,
  // ...statistics,
  // ...customerService

  ...cmps
};
