<template>
  <!-- 键盘事件监听组件 -->
  <div></div>
</template>

<script>
export default {
  name: 'keydown',
  components: {
  },
  props: [],
  data() {
    return {
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep:true,
      handler(newVal, oldVal) {
        console.log('初始化');
        console.log(newVal,oldVal);
        window.removeEventListener('keydown', this.handleKeydown)
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy() {
    console.log('销毁3');
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    handleKeydown(event) {
      if (event.keyCode === 13) {
        console.log('Enter key was pressed')
        this.$emit('keydownEvent',event)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>