import { Message, Notification } from 'element-ui'

let mixin = {
  data(){
    return{};
  },
  methods: {
    // 成功
    Success(msg) {
      Message({
        message: msg,
        type: 'success'
      });
    },
    // 警告
    Warn(msg) {
      Message({
        message: msg,
        type: 'warning'
      });
    },
    // 消息
    Info(msg) {
      Message(msg);
    },
    // 错误
    Error(msg) {
      Message({
        message: msg,
        type: 'error'
      });
    },
    // 成功通知
    successNotify(msg) {
      Notify({
        title: '成功',
        message: msg,
        type: 'success'
      });
    },
    // 警告通知
    warnNotify(msg) {
      Notify({
        title: '警告',
        message: msg,
        type: 'warning'
      });
    },
    // 消息通知
    successNotify(msg) {
      Notify.info({
        title: '消息',
        message: msg
      });
    },
    // 错误通知
    successNotify(msg) {
      Notify.error({
        title: '错误',
        message: msg
      });
    }
  }
};
export default mixin;