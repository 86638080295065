import request from '../axios'
import qs from 'qs'

const statisticsRequest = {
  /* 数据统计-start */
  // 考试数据统计查询
  examStatisticData(params) {
    return request({
      url: '/manage/dataStatistics/exam/list',
      method: 'get',
      params
    })
  },
  // 考试数据统计详情
  examStatisticDetail(params) {
    return request({
      url: '/manage/dataStatistics/exam/details',
      method: 'get',
      params
    })
  },
  // 直播数据统计查询
  liveStatisticData(params) {
    return request({
      url: '/manage/dataStatistics/live/list',
      method: "get",
      params
    })
  },
  // 直播数据统计详情
  liveStatisticDetail(params) {
    return request({
      url: '/manage/dataStatistics/live/details',
      method: "get",
      params
    })
  },
  // 教学资源数据统计列表
  resourceUpdateStatisticData(params) {
    return request({
      url: "/manage/dataStatistics/tchResource/list",
      method: "get",
      params
    })
  },
  // 教学资源数据统计详情
  resourceUpdateStatisticDetail(params) {
    return request({
      url: "/manage/dataStatistics/tchResource/details",
      method: "get",
      params
    })
  },
  // 财务统计-订单总计
  financialStatisticTotal(params) {
    return request({
      url: "/manage/dataStatistics/order/total",
      method: "get",
      params
    })
  },
  // 财务统计-订单总计-教学点统计详情
  financialStatisticStaDetail(params) {
    return request({
      url: "/manage/dataStatistics/order/total/stationList",
      method: "get",
      params
    })
  },
  // 财务统计-订单总计-学校统计详情
  financialStatisticSchDetail(params) {
    return request({
      url: "/manage/dataStatistics/order/total/tenantList",
      method: "get",
      params
    })
  },
  // 财务统计-订单
  financialStatisticDetail(params) {
    return request({
      url: "/manage/dataStatistics/order/list",
      method: "get",
      params
    })
  },
  /* 数据统计-end */
}

export default statisticsRequest;