import request from '../axios'
import qs from 'qs'

const customerServiceRequest = {
  // 客服配置
  // 分页查询
  customerServiceList(params) {
    return request({
      url: '/manage/platform/customerconfig/page',
      method: 'get',
      params
    })
  },
  // 新增
  addCustomerService(data) {
    return request({
      url: '/manage/platform/customerconfig/addCustomerConfig',
      method: 'post',
      data
    })
  },
  // 修改
  updateCustomerService(data) {
    return request({
      url: '/manage/platform/customerconfig/update',
      method: 'put',
      data
    })
  },
  // 设置省默认
  setCusServiceProvince(params) {
    return request({
      url: '/manage/platform/customerconfig/setProvinceFlag',
      method: 'put',
      params
    })
  },
  // 通过id删除
  deleteCusService(params) {
    return request({
      url: '/manage/platform/customerconfig/delete',
      method: 'delete',
      params
    })
  },
  // 管理学校-添加学校
  addManageSchool(data) {
    return request({
      url: '/manage/platform/customerconfig/managementSchool/add',
      method: 'post',
      data
    })
  },
  // 管理学校-刪除
  deleteManageSchool(params) {
    return request({
      url: '/manage/platform/customerconfig/managementSchool/delete',
      method: 'delete',
      params
    })
  },
  // 管理学校-获取添加列表
  manageSchoolAddList(params) {
    return request({
      url: '/manage/platform/customerconfig/managementSchool/getTenantDown',
      method: 'get',
      params
    })
  },
  // 管理学校-学校分页
  manageSchoolList(params) {
    return request({
      url: '/manage/platform/customerconfig/managementSchool/page',
      method: 'get',
      params
    })
  },
  // 新增通用客服企业微信链接
  addCompanyWebsite(params) {
    return request({
      url: '/admin/dict/add/generalCustomerEnterpriseWeChatLink',
      method: 'post',
      params
    })
  },
  // 获取通用客服企业微信链接
  getCompanyWebsite(params) {
    return request({
      url: `/admin/dict/item/getValue/${params.type}/${params.labelName}`,
      method: 'get'
    })
  },
  // 学校下拉
  provinceToSchoolList(params) {
    return request({
      url: '/manage/platform/customerconfig/getTenantDown',
      method: 'get',
      params
    })
  },
  // 获取客服列表
  customerServiceObjList(params) {
    return request({
      url: '/admin/user/customer/list',
      method: 'get',
      params
    })
  },
  // 获取客服下拉
  cusServiceObjList(params) {
    return request({
      url: '/manage/platform/customerconfig/getCustomerConfigDownVoByProvinceId',
      method: 'get',
      params
    })
  },
}

export default customerServiceRequest;