import request from '../axios'

const schoolSetRequest = {
  // 学校设置
  // 学校支付设置
  schoolPaySet(data) {
    return request({
      url: '/manage/platform/tenant/set/pay',
      method: 'post',
      data
    })
  },

  // 获取支付设置
  schoolPayGet(params) {
    return request({
      url: '/manage/platform/tenant/set/pay/get',
      method: 'get',
      params
    })
  },

  // 获取得分规则模板
  scoringRuleGet(params) {
    return request({
      url: '/manage/platform/tenant/set/scoreRule',
      method: 'get',
      params
    })
  },

  // 修改得分规则模板
  scoringRuleSet(obj) {
    return request({
      url: `/manage/platform/tenant/set/updateScoreRule?tenantId=${obj.tenantId}`,
      method: 'put',
      data: obj.vo
    })
  },

  // 模板短信设置
  textMessageSet(data) {
    return request({
      url: '/manage/platform/tenant/set/smsSet',
      method: 'put',
      data
    })
  },

  // 短信模板列表-获取
  textMessageGet(params) {
    return request({
      url: '/manage/platform/tenant/set/smsSet/list',
      method: 'put',
      params
    })
  },

  // 学校功能设置
  schoolWaySet(data) {
    return request({
      url: '/manage/platform/tenant/set/updateSet',
      method: 'put',
      data
    })
  },

  // 学校功能设置-获取
  schoolWayGet(params) {
    return request({
      url: '/manage/platform/tenant/set/updateSet/get',
      method: 'get',
      params
    })
  },
}

export default schoolSetRequest;