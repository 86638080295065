import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决访问同路由时报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: (resovle) => require(["../views/Home.vue"], resovle),
    redirect: { path: '/Home/index' },
    children: [
      // 首页
      {
        path: 'index',
        name: 'index',
        meta: {
          unit: '首页'
        },
        component: (resovle) => require(["../components/index.vue"], resovle)
      },
      // 任务中心
      {
        path: 'taskCenter',
        name: 'taskCenter',
        meta: {
          unit: '任务中心'
        },
        component: (resovle) => require(["../components/taskCenter.vue"], resovle)
      },
      // 个人中心
      {
        path: 'personalCenter',
        name: 'personalCenter',
        meta: {
          unit: '个人中心'
        },
        component: (resovle) => require(["../components/personalCenter.vue"], resovle)
      },
      // 平台流量监控
      {
        path: 'platformMonitor',
        name: 'platformMonitor',
        meta: {
          unit: '平台流量监控'
        },
        component: (resovle) => require(["../components/platformMonitor/index.vue"], resovle)
      },
      // 学校管理
      {
        path: 'schoolManagement/schoolList',
        name: 'schoolList',
        meta: {
          unit: '学校管理',
          breadcrumb: '学校列表',
          keepalive: true
        },
        component: (resovle) => require(["../components/schoolManagement/schoolList.vue"], resovle),
        children: [
          {
            path: 'addSchool',
            name: 'addSchool',
            meta: {
              sname: '添加学校',
              unit: '学校管理',
              breadcrumb: '学校管理,学校列表'
            },
            component: (resovle) => require(["../components/schoolManagement/addSchool.vue"], resovle)
          },
          {
            path: 'studentSituation',
            name: 'studentSituation',
            meta: {
              sname: '南昌大学学生情况',
              unit: '学校管理',
              breadcrumb: '学校管理,学校列表'
            },
            component: (resovle) => require(["../components/schoolManagement/studentSituation.vue"], resovle)
          },
          {
            path: 'courseManage',
            name: 'courseManage',
            meta: {
              sname: '课程管理',
              unit: '学校管理',
              breadcrumb: '学校管理,学校列表',
              keepalive: true
            },
            component: (resovle) => require(["../components/schoolManagement/courseManage.vue"], resovle)
          },
          {
            path: 'studentManagement',
            name: 'studentManagement',
            meta: {
              sname: '学生管理',
              unit: '学校管理',
              breadcrumb: '学校管理,学校列表'
            },
            component: (resovle) => require(["../components/schoolManagement/studentManagement.vue"], resovle)
          },
          {
            path: 'addStudent',
            name: 'addStudent',
            meta: {
              sname: '添加学生',
              unit: '学校管理',
              breadcrumb: '学校管理,学生管理'
            },
            component: (resovle) => require(["../components/schoolManagement/addStudent.vue"], resovle)
          },
          {
            path: 'teachPlan',
            name: 'teachPlan',
            meta: {
              sname: '教学计划',
              unit: '学校管理',
              breadcrumb: '学校管理,学校列表',
              keepalive: true
            },
            component: (resovle) => require(["../components/schoolManagement/teachPlan.vue"], resovle)
          },
          {
            path: 'courseArrangement',
            name: 'courseArrangement',
            meta: {
              sname: '课程安排',
              unit: '学校管理',
              breadcrumb: '学校管理,教学计划'
            },
            component: (resovle) => require(["../components/schoolManagement/courseArrangement.vue"], resovle)
          },
          {
            path: 'resourceModule',
            name: 'resourceModule',
            meta: {
              sname: '资源匹配模板',
              unit: '学校管理',
              breadcrumb: '学校管理,学校列表',
              keepalive: true
            },
            component: (resovle) => require(["../components/schoolManagement/resourceModule/index.vue"], resovle)
          },
          {
            path: 'schoolSet',
            name: 'schoolSet',
            meta: {
              sname: '学校设置',
              unit: '学校管理',
              breadcrumb: '学校管理,学校列表',
              keepalive: true
            },
            component: (resovle) => require(["../components/schoolManagement/schoolSetting/schoolSet.vue"], resovle)
          }
        ]
      },
      {
        path: 'schoolManagement/teachPlanManage',
        name: 'teachPlanManage',
        meta: {
          unit: '学校管理',
          breadcrumb: '教学计划管理'
        },
        component: () => import(/* webpackChunkName: "teachPlan" */ '../components/schoolManagement/teachPlan/index.vue'),
        children: [
          {
            path: 'courseArrange',
            name: 'courseArrange',
            meta: {
              sname: '课程安排',
              unit: '学校管理',
              breadcrumb: '学校管理,教学计划管理'
            },
            component: () => import(/* webpackChunkName: "courseArrangement" */ '../components/schoolManagement/teachPlan/courseArrange.vue')
          },
        ]
      },
      {
        path: 'schoolManagement/courseManagement',
        name: 'courseManagement',
        meta: {
          unit: '学校管理',
          breadcrumb: '课程管理'
        },
        component: () => import(/* webpackChunkName: "teachPlan" */ '../components/schoolManagement/courseManagement/index.vue'),
        children: []
      },
      {
        path: 'schoolManagement/studentManage',
        name: 'studentManage',
        meta: {
          unit: '学校管理',
          breadcrumb: '学生管理'
        },
        component: () => import(/* webpackChunkName: "studentManagement" */ '../components/schoolManagement/studentManage/index.vue'),
        children: [
          {
            path: 'addStudent',
            name: 'addStudent',
            meta: {
              sname: '添加学生',
              unit: '学校管理',
              breadcrumb: '学校管理,学生管理'
            },
            component: () => import(/* webpackChunkName: "addStudent" */ '../components/schoolManagement/addStudent.vue')
          },
        ]
      },
      // 助学管理
      {
        path: 'aidManagement/aidClassification',
        name: 'aidClassification',
        meta: {
          unit: '助学管理',
          breadcrumb: '助学分类'
        },
        component: (resovle) => require(["../components/aidManagement/aidClassification.vue"], resovle),
        children: [
          {
            path: 'majorNumDetails',
            name: 'majorNumDetails',
            meta: {
              sname: '专业数详情',
              unit: '助学管理',
              breadcrumb: '助学管理,助学分类'
            },
            component: (resovle) => require(["../components/aidManagement/aidClass/majorNumDetails.vue"], resovle)
          },
          {
            path: 'classNumDetails',
            name: 'classNumDetails',
            meta: {
              sname: '班级数详情',
              unit: '助学管理',
              breadcrumb: '助学管理,助学分类'
            },
            component: (resovle) => require(["../components/aidManagement/aidClass/classNumDetails.vue"], resovle),
            children: [
              {
                path: 'studyNumDetails',
                name: 'studyNumDetails',
                meta: {
                  sname: '学习人数详情',
                  unit: '助学管理',
                  breadcrumb: '助学管理,助学分类,班级数详情'
                },
                component: (resovle) => require(["../components/aidManagement/aidClass/studyNumDetails.vue"], resovle),
                children: [
                  {
                    path: 'studentStudyDetails',
                    name: 'studentStudyDetails',
                    meta: {
                      sname: '学生详情',
                      unit: '助学管理',
                      breadcrumb: '助学管理,助学分类,班级数详情,学习人数详情'
                    },
                    component: (resovle) => require(["../components/aidManagement/aidClass/studentStudyDetails.vue"], resovle)
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'aidManagement/classManagement',
        name: 'classManagement',
        meta: {
          unit: '助学管理',
          breadcrumb: '班级管理'
        },
        component: (resovle) => require(["../components/aidManagement/classManagement.vue"], resovle),
        children: [
          {
            path: 'classStudyNumDetails',
            name: 'classStudyNumDetails',
            meta: {
              sname: '学习人数详情',
              unit: '助学管理',
              breadcrumb: '助学管理,班级管理'
            },
            component: (resovle) => require(["../components/aidManagement/classManage/classStudyNumDetails.vue"], resovle)
          },
          {
            path: 'coursewareDetails',
            name: 'coursewareDetails',
            meta: {
              sname: '课件详情',
              unit: '助学管理',
              breadcrumb: '助学管理,班级管理'
            },
            component: (resovle) => require(["../components/aidManagement/classManage/coursewareDetails.vue"], resovle),
            children: [
              {
                path: 'viewCourseware',
                name: 'viewCourseware',
                meta: {
                  sname: '课件预览',
                  unit: '助学管理',
                  breadcrumb: '助学管理,班级管理,课件详情'
                },
                component: (resovle) => require(["../components/aidManagement/classManage/viewCourseware.vue"], resovle)
              }
            ]
          },
          {
            path: 'specialExercises',
            name: 'specialExercises',
            meta: {
              sname: '专项练习',
              unit: '助学管理',
              breadcrumb: '助学管理,班级管理'
            },
            component: (resovle) => require(["../components/aidManagement/classManage/specialExercises.vue"], resovle)
          },
          {
            path: 'refinedEdition',
            name: 'refinedEdition',
            meta: {
              unit: '助学管理',
              breadcrumb: '助学管理,班级管理'
            },
            component: (resovle) => require(["../components/aidManagement/classManage/refinedEdition.vue"], resovle)
          },
        ]
      },
      {
        path: 'aidManagement/liveBroadcast',
        name: 'liveBroadcast',
        meta: {
          unit: '助学管理',
          breadcrumb: '直播管理'
        },
        component: (resovle) => require(["../components/aidManagement/liveManage/liveBroadcast.vue"], resovle)
      },
      {
        path: 'aidManagement/sourceManagement',
        name: 'sourceManagement',
        meta: {
          unit: '助学管理',
          breadcrumb: '成考生源管理'
        },
        component: (resovle) => require(["../components/aidManagement/sourceManagement.vue"], resovle)
      },
      {
        path: 'aidManagement/sourceManagement/studentDetails',
        name: 'studentDetails',
        meta: {
          unit: '助学管理',
          breadcrumb: '成考学生详情'
        },
        component: (resovle) => require(["../components/aidManagement/studentDetails.vue"], resovle)
      },
      // 学位外语管理
      {
        path: 'degreeEnglish/degreeClassManagemant',
        name: 'degreeClassManagemant',
        meta: {
          unit: '学位外语管理',
          breadcrumb: '班级管理'
        },
        component: (resovle) => require(["../components/degreeEnglish/degreeClassManagemant.vue"], resovle),
        children: [
          {
            path: 'degreeCourse',
            name: 'degreeCourse',
            meta: {
              sname: '课件详情',
              unit: '学位外语管理',
              breadcrumb: '学位外语管理,班级管理'
            },
            component: (resovle) => require(["../components/degreeEnglish/module/degreeCourse.vue"], resovle)
          },
          {
            path: 'degreeExercises',
            name: 'degreeExercises',
            meta: {
              sname: '专项练习详情',
              unit: '学位外语管理',
              breadcrumb: '学位外语管理,班级管理'
            },
            component: (resovle) => require(["../components/degreeEnglish/module/degreeExercises.vue"], resovle)
          },
          {
            path: 'degreeExamination',
            name: 'degreeExamination',
            meta: {
              unit: '学位外语管理',
              breadcrumb: '学位外语管理,班级管理'
            },
            component: (resovle) => require(["../components/degreeEnglish/module/degreeExamination.vue"], resovle)
          },
          {
            path: 'degreeSudyDetails',
            name: 'degreeSudyDetails',
            meta: {
              sname: '学习人数详情',
              unit: '学位外语管理',
              breadcrumb: '学位外语管理,班级管理'
            },
            component: (resovle) => require(["../components/degreeEnglish/module/degreeSudyDetails.vue"], resovle)
          }
        ]
      },
      // 审核管理
      {
        path: 'auditManagement/orderManagement',
        name: 'orderManagement',
        meta: {
          unit: '审核管理',
          breadcrumb: '订单管理'
        },
        component: (resovle) => require(["../components/orderManagement/orderManagement.vue"], resovle),
        children: [
          {
            path: 'orderDetails',
            name: 'orderDetails',
            meta: {
              sname: '订单详情',
              unit: '审核管理',
              breadcrumb: '审核管理,订单管理'
            },
            component: (resovle) => require(["../components/orderManagement/orderDetails.vue"], resovle)
          }
        ]
      },
      {
        path: 'auditManagement/auditManagements',
        name: 'auditManagements',
        meta: {
          unit: '审核管理',
          breadcrumb: '缴费开通审核'
        },
        component: (resovle) => require(["../components/orderManagement/auditManagement.vue"], resovle),
        children: [
          {
            path: 'auditDatails',
            name: 'auditDatails',
            meta: {
              sname: '审批详情',
              unit: '审核管理',
              breadcrumb: '审核管理,缴费开通审核'
            },
            component: (resovle) => require(["../components/orderManagement/auditDatails.vue"], resovle)
          },
          {
            path: 'studentListUrl',
            name: 'studentListUrl',
            meta: {
              sname: '查看学生名单',
              unit: '审核管理',
              breadcrumb: '审核管理,缴费开通审核,审批'
            },
            component: (resovle) => require(["../components/orderManagement/studentListUrl.vue"], resovle)
          }

        ]
      },
      {
        path: 'auditManagement/closePermissionApproval',
        name: 'closePermissionApproval',
        meta: {
          unit: '审核管理',
          breadcrumb: '关闭权限审核'
        },
        component: (resovle) => require(["../components/orderManagement/closePermission/index.vue"], resovle),
        children: [
          {
            path: 'permissionDetail',
            name: 'permissionDetail',
            meta: {
              sname: '审批详情',
              unit: '审核管理',
              breadcrumb: '审核管理,关闭权限审核'
            },
            component: (resovle) => require(["../components/orderManagement/closePermission/permissionDetail.vue"], resovle)
          },
          {
            path: 'closeStudentListUrl',
            name: 'closeStudentListUrl',
            meta: {
              sname: '查看学生名单',
              unit: '审核管理',
              breadcrumb: '审核管理,关闭权限审核,审批'
            },
            component: (resovle) => require(["../components/orderManagement/closePermission/closeStudentListUrl.vue"], resovle)
          }
        ]
      },
      {
        path: 'auditManagement/deleteStudentReview',
        name: 'deleteStudentReview',
        meta: {
          unit: '审核管理',
          breadcrumb: '删除学生审核'
        },
        component: (resovle) => require(["../components/orderManagement/deleteStudentReview/index.vue"], resovle),
        children: [
          {
            path: 'perDeleteDetail',
            name: 'perDeleteDetail',
            meta: {
              sname: '审批详情',
              unit: '审核管理',
              breadcrumb: '审核管理,删除学生审核'
            },
            component: (resovle) => require(["../components/orderManagement/deleteStudentReview/perDeleteDetail.vue"], resovle)
          },
          {
            path: 'deleteStudentListUrl',
            name: 'deleteStudentListUrl',
            meta: {
              sname: '查看学生名单',
              unit: '审核管理',
              breadcrumb: '审核管理,删除学生审核,审批'
            },
            component: (resovle) => require(["../components/orderManagement/deleteStudentReview/deleteStudentListUrl.vue"], resovle)
          }
        ]
      },
      // 角色管理
      {
        path: 'role/roleManagement',
        name: 'roleManagement',
        meta: {
          unit: '角色管理',
          breadcrumb: '角色管理'
        },
        component: (resovle) => require(["../components/role/roleManagement.vue"], resovle),
        children: [
          {
            path: 'authorityManagement',
            name: 'authorityManagement',
            meta: {
              sname: '功能权限设置',
              unit: '角色管理',
              breadcrumb: '角色管理,角色管理'
            },
            component: (resovle) => require(["../components/role/authorityManagement.vue"], resovle)
          }
        ]
      },
      {
        path: 'role/userManagement',
        name: 'userManagement',
        meta: {
          unit: '角色管理',
          breadcrumb: '用户管理'
        },
        component: (resovle) => require(["../components/role/userManagement.vue"], resovle),
        children: [
          {
            path: 'addUser',
            name: 'addUser',
            meta: {
              sname: '添加用户',
              unit: '角色管理',
              breadcrumb: '角色管理,用户管理'
            },
            component: (resovle) => require(["../components/role/addUser.vue"], resovle)
          }
        ]
      },
      {
        path: 'role/schoolRoleManagement',
        name: 'schoolRoleManagement',
        meta: {
          unit: '角色管理',
          breadcrumb: '学校角色管理'
        },
        component: (resovle) => require(["../components/role/schoolRoleManagement.vue"], resovle),
        children: [
          {
            path: 'schoolAuthorityManagement',
            name: 'schoolAuthorityManagement',
            meta: {
              sname: '功能权限设置',
              unit: '角色管理',
              breadcrumb: '角色管理,学校角色管理'
            },
            component: (resovle) => require(["../components/role/schoolAuthorityManagement.vue"], resovle)
          }
        ]
      },
      // 专业库管理
      {
        path: 'library/libraryManagement',
        name: 'libraryManagement',
        meta: {
          unit: '专业库管理',
          breadcrumb: '专业库管理'
        },
        component: (resovle) => require(["../components/library/libraryManagement.vue"], resovle)
      },
      // 数据统计
      {
        path: 'dataStatistics/financialStatistics',
        name: 'financialStatistics',
        meta: {
          unit: '数据统计',
          breadcrumb: '财务统计'
        },
        component: (resovle) => require(["../components/dataStatistics/financial/index.vue"], resovle)
      },
      {
        path: 'dataStatistics/liveStatistics',
        name: 'liveStatistics',
        meta: {
          unit: '数据统计',
          breadcrumb: '直播情况统计'
        },
        component: (resovle) => require(["../components/dataStatistics/live/index.vue"], resovle)
      },
      {
        path: 'dataStatistics/examStatistics',
        name: 'examStatistics',
        meta: {
          unit: '数据统计',
          breadcrumb: '考试情况统计'
        },
        component: (resovle) => require(["../components/dataStatistics/exam/index.vue"], resovle)
      },
      {
        path: 'dataStatistics/resourceUpdateStatistics',
        name: 'resourceUpdateStatistics',
        meta: {
          unit: '数据统计',
          breadcrumb: '资源更新统计'
        },
        component: (resovle) => require(["../components/dataStatistics/resourceUpdate/index.vue"], resovle)
      },
      // 客服管理
      {
        path: 'customerService/customerServiceConfig',
        name: 'customerServiceConfig',
        meta: {
          unit: '客服管理',
          breadcrumb: '客服配置'
        },
        component: (resovle) => require(["../components/customerService/customerServiceConfig.vue"], resovle)
      },
      // 日志管理
      {
        path: 'logManagement/loginLog',
        name: 'loginLog',
        meta: {
          unit: '日志管理',
          breadcrumb: '登录日志'
        },
        component: (resovle) => require(["../components/logManagement/loginLog.vue"], resovle)
      },
      {
        path: 'logManagement/operationLog',
        name: 'operationLog',
        meta: {
          unit: '日志管理',
          breadcrumb: '操作日志'
        },
        component: (resovle) => require(["../components/logManagement/operationLog.vue"], resovle)
      },
      // 版本管理
      {
        path: 'version/versionManagement',
        name: 'versionManagement',
        meta: {
          unit: '版本管理',
          breadcrumb: '版本管理'
        },
        component: (resovle) => require(["../components/version/versionManagement.vue"], resovle)
      },
      // 系统管理
      // 字典管理
      {
        path: 'system/dictionaryManagement',
        name: 'dictionaryManagement',
        meta: {
          unit: '系统管理',
          breadcrumb: '字典管理'
        },
        component: (resovle) => require(["../components/system/dictionaryManagement.vue"], resovle),
        children: [
          {
            path: 'dictionaryItemManagement',
            name: 'dictionaryItemManagement',
            meta: {
              sname: '字典项管理',
              unit: '系统管理',
              breadcrumb: '系统管理,字典管理'
            },
            component: (resovle) => require(["../components/system/dictionaryItemManagement.vue"], resovle)
          }
        ]
      },
      // 菜单管理
      {
        path: 'system/menuManagement',
        name: 'menuManagement',
        meta: {
          unit: '系统管理',
          breadcrumb: '菜单管理'
        },
        component: (resovle) => require(["../components/system/menuManagement.vue"], resovle)
      },
      // 登录设置
      {
        path: 'system/loginSetting',
        name: 'loginSetting',
        meta: {
          unit: '系统管理',
          breadcrumb: '登录设置'
        },
        component: (resovle) => require(["../components/system/loginSetting.vue"], resovle)
      },
      // 备份管理
      {
        path: 'system/backupManagement',
        name: 'backupManagement',
        meta: {
          unit: '系统管理',
          breadcrumb: '登录设置'
        },
        component: (resovle) => require(["../components/system/backupManagement.vue"], resovle)
      },
      {
        path: 'system/menuManagement',
        name: 'menuManagement',
        meta: {
          unit: '系统管理',
          breadcrumb: '菜单管理'
        },
        component: (resovle) => require(["../components/system/menuManagement.vue"], resovle)
      },
      // 文章管理
      {
        path: 'article/operationalGuidelines',
        name: 'operationalGuidelines',
        meta: {
          unit: '文章管理',
          breadcrumb: '操作指引'
        },
        component: (resovle) => require(["../components/article/operationalGuidelines.vue"], resovle),
        children: [
          {
            path: 'addOperational',
            name: 'addOperational',
            meta: {
              sname: '添加文章',
              unit: '文章管理',
              breadcrumb: '文章管理,添加文章'
            },
            component: (resovle) => require(["../components/article/addOperational.vue"], resovle)
          }
        ]
      },
      {
        path: 'article/commonProblem',
        name: 'commonProblem',
        meta: {
          unit: '文章管理',
          breadcrumb: '常见问题'
        },
        component: (resovle) => require(["../components/article/commonProblem.vue"], resovle),
        children: [
          {
            path: 'addArticle',
            name: 'addArticle',
            meta: {
              sname: '添加文章',
              unit: '文章管理',
              breadcrumb: '文章管理,添加文章'
            },
            component: (resovle) => require(["../components/article/addArticle.vue"], resovle)
          }
        ]
      },
      {
        path: 'article/userAgreement',
        name: 'userAgreement',
        meta: {
          unit: '文章管理',
          breadcrumb: '用户协议'
        },
        component: (resovle) => require(["../components/article/userAgreement.vue"], resovle)
      },
      {
        path: 'article/privacyClause',
        name: 'privacyClause',
        meta: {
          unit: '文章管理',
          breadcrumb: '隐私条款'
        },
        component: (resovle) => require(["../components/article/privacyClause.vue"], resovle)
      },
      // 课件预览
      {
        path: 'viewCoursewareModule',
        name: 'viewCoursewareModule',
        meta: {
          // unit: '系统管理',
          // breadcrumb: '课件预览'
        },
        component: (resovle) => require(["../components/commonModule/courseWare/index.vue"], resovle)
      },
    ]
  },
  {
    path: '/Login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: (resovle) => require(["../views/Login.vue"], resovle)
  },
]

const router = new VueRouter({
  routes
})

export default router
